import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Form from "./faturaForm";
import FormDataPagamento from "./faturaFormDataPagamento";
import FormMultiplo from "./faturaFormMultiplo";
// import FaturaPdf from "./faturaPdf";
import Select from "../common/form/select";
import casaPreta from "../assets/images/casaPreta.png";
import solVerde from "../assets/images/solVerde.png";
import solVermelho from "../assets/images/solVermelho.png";
import solPreto from "../assets/images/solPreto.png";
import { DisplayPDF } from "../displayPDF/displayPDF";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import Button from "../common/button/button";
import DateFormat from "../common/dateFormat/DateFormat";
import TabelaFaturaClientePDF from "../htmlToPdf/TabelaFaturaClientePDF";
import { filtrarUcPorDataDesligamentoCompetencia } from "../utils/filtrarUcPorDataDesligamentoCompetencia";
import { htmlToPdf } from "../htmlToPdf/htmlToPdf";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { ModalAlert } from "../common/dialog/modalAlert";

import {
    setModoTela,
    initForm,
    setFiltro,
    salvar,
    salvarMultiplos,
    excluir,
    getLista,
    buscaPdf,
    setDataPagamento,
    setAguardando,
    getCorDataVencimentoFatura,
    resetarFiltro,
    incluirDownloadRealizado,
    excluirDownloadRealizado,
    baixarMultiplasFaturasPdf,
    imprimirMultiplasFaturas,
    getTotalFaturaEmpenho,
    buscarPdfFaturaAdicional,
} from "./faturaActions";

import { setArquivoPDF } from "../displayPDF/displayPDFActions";

import { reprocessar, reprocessarTodas, executarManutencao } from "./faturaItem/faturaItemActions";
import { getListaGrupoFaturamento } from "../cliente/clienteActions";
import { getLista as getListaEmpenho } from "../empenho/empenhoActions";
import { Loading } from "../common/loading/loading";
import { setError, setSuccess } from "../common/toast/toast";

class FaturaVisualizacao extends Component {
    state = {
        statusCorBranca: true, //não importado
        statusCorAzul: true, //importadas e NÃO vencidas
        statusCorAmarela: true, //falta 2 dias para o vencimento
        statusCorVermelha: true, //vencidas e não pagas
        statusCorVerde: true, //qualquer uma paga
        statusImportacaoSolarVerde: false,
        statusImportacaoSolarVermelha: false,
        situacaoDownload: 1, //Mostra toddas
        exibirModalSelecaoFatura: false,
        itemPdfSelecionado: null,
    };
    componentWillMount() {
        this.props.getListaGrupoFaturamento();

        if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
            this.props.getListaEmpenho(this.props.usuarioLogado.id_cliente, new Date().getFullYear());
        }
        this.props.getLista();
    }

    componentWillUnmount() {
        this.props.resetarFiltro();
    }

    filtrarFaturasTabela(props) {
        return props.lista.filter((item) => {
            const faturaIsenta = item.importacao_validada && (item.erros_importacao || []).filter((erro) => erro.validado).length == 1;

            const foiValidada = !(item.erros_importacao || []).length || item.importacao_validada;

            let [azuis, amarelos, vermelhos] = [false, false, false];
            if (item.data_vencimento) {
                const doisDiasMs = 1000 * 60 * 60 * 24 * 2;
                const dataVencimentoMs = new Date(item.data_vencimento).getTime();
                const data2DiasParaVencimentoMs = new Date(dataVencimentoMs - doisDiasMs).getTime();
                const dataAtual = new Date();
                dataAtual.setHours(0, 0, 0, 0);
                const dataAtualMs = dataAtual.getTime();
                azuis =
                    !faturaIsenta &&
                    !item.data_pagamento &&
                    foiValidada &&
                    (dataAtualMs < data2DiasParaVencimentoMs || item.competencia <= 202309) && //faturas de 09/2023 pra trás, mesmo não pagas fica azul pq a rotina n tava rodando ainda
                    this.state.statusCorAzul;

                amarelos =
                    item.competencia > 202309 && //ignora as vermelhas com competência menor ou igual a 09/2023, pois a rotina foi implantada em outubro
                    !faturaIsenta &&
                    !item.data_pagamento &&
                    foiValidada &&
                    item.data_vencimento &&
                    dataAtualMs >= data2DiasParaVencimentoMs &&
                    dataAtualMs < dataVencimentoMs &&
                    this.state.statusCorAmarela;

                vermelhos =
                    item.competencia > 202309 && //ignora as vermelhas com competência menor ou igual a 09/2023, pois a rotina foi implantada em outubro
                    !faturaIsenta &&
                    !item.data_pagamento &&
                    foiValidada &&
                    item.data_vencimento &&
                    dataAtualMs >= dataVencimentoMs &&
                    this.state.statusCorVermelha;
            }
            if (!item.data_vencimento) {
                azuis = !faturaIsenta && !item.data_pagamento && foiValidada && this.state.statusCorAzul;
            }
            const verdes = (item.data_pagamento || faturaIsenta) && this.state.statusCorVerde;

            const brancos = !faturaIsenta && !item.data_pagamento && !foiValidada && this.state.statusCorBranca;

            const filtroPorCores = brancos || verdes || azuis || amarelos || vermelhos;

            const filtroImportacaoSolar = this.filtrarImportacaoSolar(item);

            const mesmoGrupoFaturamento = !props.filtro.id_grupo_faturamento || item.id_grupo_faturamento == props.filtro.id_grupo_faturamento;

            //1 traz todas, 2 apenas baixadas, 3 apenas download pendente
            const filtroSituacaoDownload =
                this.state.situacaoDownload == 2
                    ? this.validarDownloadRealizado(item)
                    : this.state.situacaoDownload == 3
                    ? !this.validarDownloadRealizado(item)
                    : this.state.situacaoDownload == 1;
            return (
                mesmoGrupoFaturamento &&
                filtroPorCores &&
                filtroImportacaoSolar &&
                filtroSituacaoDownload &&
                props.usuarioLogado &&
                (props.usuarioLogado.administrador || item.importacao_validada)
            );
        });
    }

    filtrarUCsTabela(props) {
        if (!props.filtro) return [];

        return filtrarUcPorDataDesligamentoCompetencia({
            listaUnidadeConsumidora: props.listaUnidadeConsumidora,
            ano: props.filtro.ano,
            mes: props.filtro.mes,
        }).filter((item) => {
            const filtroImportacaoSolar = this.filtrarImportacaoSolar(item);
            return (
                props.usuarioLogado &&
                //ou não filtra cliente ou retorna as faturas com o id do cliente do filtro
                (!props.filtro.id_cliente || props.filtro.id_cliente == item.id_cliente) &&
                //ou não filtra a UC ou retorna as faturas da UC que lista no select de uc ou as que estão selecionadas no select
                ((!props.filtro.unidades_consumidoras.length && this.listarUCsFiltradas().find((uc) => uc.id == item.id)) ||
                    props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)) &&
                //o ano do filtro tem que ser menor ou igual ao ano atual
                props.filtro.ano &&
                props.filtro.ano <= new Date().getFullYear() &&
                props.filtro.mes &&
                //verdadeiro se a competencia do filtro for menor ou igual a atual
                parseInt(`${props.filtro.ano}${props.filtro.mes}`) <= parseInt(`${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}`) &&
                //verdadeiro se não existir fatura da unidade consumidora
                (!props.lista.find((fatura) => fatura.id_unidade_consumidora == item.id) ||
                    //ou existe na lista mas é vermelho e o usuário não é administrador
                    (props.lista.find((fatura) => {
                        const vermelhos = (fatura.erros_importacao || []).length && !fatura.importacao_validada;
                        return fatura.id_unidade_consumidora == item.id && vermelhos;
                    }) &&
                        !props.usuarioLogado.administrador)) &&
                this.state.statusCorBranca &&
                (!this.props.filtro.id_grupo_faturamento || this.props.filtro.id_grupo_faturamento == item.id_grupo_faturamento) &&
                filtroImportacaoSolar
            );
        });
    }

    render() {
        if (this.props.pdf) {
            if (this.state.exibirModalSelecaoFatura) {
                this.setState({ ...this.state, exibirModalSelecaoFatura: false, itemPdfSelecionado: null });
            }
            //											   após baixar tira o modo bold da linha da fatura
            return (
                <DisplayPDF
                    setDownloadRealizado={() => this.props.incluirDownloadRealizado([this.props.id_pdf])}
                    closePdf={this.props.setArquivoPDF}
                    pdfBase64={this.props.pdf}
                    nomePdf={this.props.nomePdf}
                    zoomParametro={1.5}
                />
            );
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                {this.state.exibirModalSelecaoFatura ? (
                    <ModalAlert
                        titulo="Selecione o documento a ser visualizado"
                        handleClose={() => this.setState({ ...this.state, exibirModalSelecaoFatura: false, itemPdfSelecionado: null })}
                    >
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <tbody>
                                <tr style={{ borderBlock: "1px solid #ccc", paddingTop: 30 }}>
                                    <td style={{ paddingInline: 50 }}>Fatura Principal</td>
                                    <td>
                                        <ButtonTable
                                            style={{ margin: 0 }}
                                            title="Visualizar PDF"
                                            type={"primary"}
                                            icon={"fas fa-file-pdf"}
                                            visible={true}
                                            event={() => {
                                                this.props.buscaPdf(
                                                    this.state.itemPdfSelecionado.id,
                                                    `UC-${this.state.itemPdfSelecionado.nome_unidade_consumidora.split("-")[0].trim()}_${String(
                                                        this.state.itemPdfSelecionado.competencia
                                                    ).substring(4, 6)}-${String(this.state.itemPdfSelecionado.competencia).substring(0, 4)}`
                                                );
                                                this.props.setArquivoPDF();
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Fatura Adicional - Custo Energia ACL (Mercado Livre)</td>
                                    <td>
                                        <ButtonTable
                                            style={{ margin: 0 }}
                                            title="Visualizar PDF"
                                            type={"warning"}
                                            icon={"fas fa-file-pdf"}
                                            disabled={!this.state.itemPdfSelecionado.arquivo_pdf_custo_energia_acl}
                                            visible={true}
                                            event={() => {
                                                this.props.buscarPdfFaturaAdicional({ id_fatura: this.state.itemPdfSelecionado.id, id_produto: 19001 });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Fatura Adicional - Custo Gestão ACL (Mercado Livre)</td>
                                    <td>
                                        <ButtonTable
                                            style={{ margin: 0 }}
                                            title="Visualizar PDF"
                                            type={"dark"}
                                            icon={"fas fa-file-pdf"}
                                            disabled={!this.state.itemPdfSelecionado.arquivo_pdf_custo_gestao_acl}
                                            visible={true}
                                            event={() => {
                                                this.props.buscarPdfFaturaAdicional({ id_fatura: this.state.itemPdfSelecionado.id, id_produto: 19002 });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Fatura Adicional - Tx. Contribuição Associativa CCEE (Mercado Livre)</td>
                                    <td>
                                        <ButtonTable
                                            style={{ margin: 0 }}
                                            title="Visualizar PDF"
                                            type={"info"}
                                            icon={"fas fa-file-pdf"}
                                            disabled={!this.state.itemPdfSelecionado.arquivo_pdf_contribuicao_associativa_ccee}
                                            visible={true}
                                            event={() => {
                                                this.props.buscarPdfFaturaAdicional({ id_fatura: this.state.itemPdfSelecionado.id, id_produto: 19003 });
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ModalAlert>
                ) : null}

                <Content>
                    {this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela == "cadastroMultiplo" ? <FormMultiplo onSubmit={this.props.salvarMultiplos} /> : null}
                    {this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {/* {this.props.modoTela == "relatorio" ? <FaturaPdf /> : null} */}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                    {this.props.modoTela == "alterarDataPagamento" ? <FormDataPagamento onSubmit={this.props.setDataPagamento} /> : null}
                    {this.props.modoTela == "excluirDataPagamento" ? <FormDataPagamento excluir onSubmit={this.props.setDataPagamento} /> : null}
                </Content>
            </div>
        );
    }

    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor || "#000000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "52px",
            margin: 5,
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }

    filtrarImportacaoSolar(item) {
        const importacaoSolarVermelha = this.state.statusImportacaoSolarVermelha && item.unidade_geradora && !item.unidade_remota && parseFloat(item.quantidade_geracao || 0) <= 0;
        const importacaoSolarVerde = this.state.statusImportacaoSolarVerde && item.unidade_geradora && !item.unidade_remota && parseFloat(item.quantidade_geracao || 0) > 0;
        const importacaoSolarIgnorada = !this.state.statusImportacaoSolarVermelha && !this.state.statusImportacaoSolarVerde;

        const filtroImportacaoSolar = importacaoSolarVermelha || importacaoSolarVerde || importacaoSolarIgnorada;
        return filtroImportacaoSolar;
    }

    listarUCsFiltradas() {
        const ucsFiltradas = this.props.listaUnidadeConsumidora.filter(
            (item) =>
                ((!this.props.filtro.id_cliente && this.props.usuarioLogado && this.props.usuarioLogado.administrador) || item.id_cliente == this.props.filtro.id_cliente) &&
                (!this.props.filtro.id_grupo_faturamento || item.id_grupo_faturamento == this.props.filtro.id_grupo_faturamento) &&
                ((this.props.filtro.irrigante && item.irrigante) || (this.props.filtro.naoIrrigante && !item.irrigante))
        );
        return ucsFiltradas;
    }

    validarDownloadRealizado(item) {
        return !!(item.id_usuario_download_realizado || []).find((id_usuario) => id_usuario == this.props.usuarioLogado.id);
    }

    copiarTextoClipboard(texto) {
        navigator.clipboard
            .writeText(texto)
            .then(() => {
                setSuccess(`Conteúdo copiado: ${texto}`);
            })
            .catch((err) => {
                setError("Não foi possível copiar o conteúdo");
            });
    }

    lista() {
        let totalValorFatura = 0;
        let statusAtivos = [];
        if (this.state.statusCorBranca) statusAtivos.push("Não importado");
        if (this.state.statusCorAzul) statusAtivos.push("Importado e não vencido");

        if (this.state.statusCorAmarela) statusAtivos.push("Um ou dois dias para o vencimento");
        if (this.state.statusCorVermelha) statusAtivos.push("Vencendo hoje ou vencido");

        if (this.state.statusCorVerde) statusAtivos.push("Pago");

        if (this.state.statusImportacaoSolarVerde) statusAtivos.push("Solar importado");

        if (this.state.statusImportacaoSolarVermelha) statusAtivos.push("Solar não importado");

        if (
            this.state.statusCorBranca &&
            this.state.statusCorVerde &&
            this.state.statusCorAzul &&
            this.state.statusCorAmarela &&
            this.state.statusCorVermelha &&
            !this.state.statusImportacaoSolarVerde &&
            !this.state.statusImportacaoSolarVermelha
        )
            statusAtivos = "Mostrar todos";

        //DADOS PARA IMPRESSÃO DO RELATÓRIO ---------------
        const cabecalho = (
            <NewTr
                backgroundColorTr={"#FFFFFF"}
                backgroundColorTd={"#F5F5F5"}
                celulas={
                    <>
                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter title="Status da Importação das Faturas de Energia Elétrica">
                            Status Imp. Fatura
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={65} alignCenter title="Status da Importação de Produção de Energia Elétrica Solar">
                            Status Imp. Prod. Solar
                        </Th>
                        <Th
                            sticky
                            style={{ fontSize: "0.7rem" }}
                            minWidth={75}
                            alignCenter
                            backgroundColor={"#f9f9f9"}
                            title="Saldo de Produção Solar da Fatura de Energia Elétrica"
                        >
                            Saldo Prod. Solar
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter>
                            Competência
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                            Cliente
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                            Nº da Nota Fiscal
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                            UC
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter title="Grupo de Faturamento">
                            Grp. Fat.
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter title="Tipo de Medição">
                            Tp. Med.
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter>
                            Data de Vencimento
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter>
                            Data de Pagamento
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter>
                            Usuário de Alteração Data de Pagamento
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter>
                            Total Fatura
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                            PDF
                        </Th>
                        <Th sticky style={{ fontSize: "0.7rem" }}>
                            Download Realizado
                        </Th>
                    </>
                }
            />
        );

        const linhas = [];
        let rodape = null;
        const usuarioLogadoAcessoCliente = this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente;

        //se for admin logado só mostra filtro e card de empenho quando seleciona um cliente do tipo prefeitura
        //se for cliente logado, só mostra filtro e card de empenho se o cliente logado for do tipo prefeitura
        const clienteSelecionado = this.props.listaCliente.find((cli) => cli.id == this.props.filtro.id_cliente);
        const exibirFiltroEmpenho = this.props.usuarioLogado.cliente_prefeitura || (clienteSelecionado && clienteSelecionado.cliente_prefeitura);
        const empenhoSelecionado = exibirFiltroEmpenho ? this.props.listaEmpenho.find((emp) => emp.id == this.props.filtro.id_empenho) : null;
        return (
            <ContentCard>
                <ContentCardBody>
                    <ContentCardHeader>
                        <Row style={{ paddingBlock: 15 }}>
                            {!usuarioLogadoAcessoCliente ? (
                                <Select
                                    readOnly={this.props.aguardando || this.props.filtro.id_empenho}
                                    name="id_cliente"
                                    options={this.props.listaCliente}
                                    label="Cliente"
                                    cols="12 12 5 5"
                                    placeholder="Selecione o Cliente"
                                    value={this.props.filtro.id_cliente}
                                    onChange={(value) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_cliente: value,
                                            id_empenho: null,
                                            unidades_consumidoras: [],
                                        });
                                        this.props.getListaEmpenho(value, new Date().getFullYear());
                                        this.props.getLista();
                                    }}
                                />
                            ) : null}
                            <Select
                                readOnly={this.props.aguardando || this.props.filtro.id_empenho}
                                name="ano"
                                options={Array.from({ length: 20 }, (v, i) => ({
                                    id: new Date().getFullYear() - 5 + i,
                                    valor: new Date().getFullYear() - 5 + i,
                                }))}
                                label="Ano"
                                cols="12 6 2 2"
                                placeholder="Selecione o ano"
                                value={this.props.filtro.ano}
                                isClearable={false}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        ano: value,
                                    });
                                    this.props.getLista();
                                }}
                            />
                            <Select
                                readOnly={this.props.aguardando}
                                name="mes"
                                options={this.props.listaMeses}
                                label="Mês"
                                cols="12 6 2 2"
                                placeholder="Selecione o mês"
                                value={this.props.filtro.mes}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        mes: value,
                                    });
                                    this.props.getLista();
                                }}
                            />{" "}
                            {usuarioLogadoAcessoCliente ? (
                                <>
                                    <Select
                                        readOnly={this.props.aguardando}
                                        name="id_grupo_faturamento"
                                        options={this.props.listaGrupoFaturamento}
                                        label="Tipo de Faturamento"
                                        cols="12 12 3 3"
                                        placeholder="Selecione o tipo de faturamento"
                                        value={this.props.filtro.id_grupo_faturamento}
                                        onChange={(id_grupo_faturamento) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_grupo_faturamento,
                                            });
                                            this.props.getLista();
                                        }}
                                    />
                                    <Select
                                        readOnly={this.props.aguardando}
                                        options={[
                                            {
                                                id: 1,
                                                valor: "Todas",
                                            },
                                            {
                                                id: 2,
                                                valor: "Download Realizado",
                                            },
                                            {
                                                id: 3,
                                                valor: "Download Pendente",
                                            },
                                        ]}
                                        label="Situação do Download"
                                        cols="12 12 2 2"
                                        placeholder="Selecione a situação do download"
                                        value={this.state.situacaoDownload}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(value) => {
                                            this.setState({
                                                ...this.state,
                                                situacaoDownload: value,
                                            });
                                        }}
                                    />
                                </>
                            ) : null}
                            {exibirFiltroEmpenho ? (
                                <Select
                                    readOnly={this.props.aguardando}
                                    options={this.props.listaEmpenho.map((emp) => ({ id: emp.id, valor: emp.numero }))}
                                    label="Nº do Empenho"
                                    cols="12 12 3 3"
                                    placeholder="Selecione o Nº do Empenho"
                                    value={this.props.filtro.id_empenho}
                                    onChange={(value) => {
                                        const empenhoSelecionado = this.props.listaEmpenho.find((emp) => emp.id == value);
                                        const listaUcsEmpenho = empenhoSelecionado
                                            ? empenhoSelecionado.unidades_consumidoras.map((uc) => ({
                                                  value: uc.id,
                                                  label: uc.numero + " - " + uc.nome,
                                              }))
                                            : [];
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_empenho: empenhoSelecionado ? value : null,
                                            ano: new Date().getFullYear(),
                                            unidades_consumidoras: [...listaUcsEmpenho],
                                        });
                                        this.props.getTotalFaturaEmpenho();
                                        this.props.getLista();
                                    }}
                                />
                            ) : (
                                <Grid cols="12 12 3 3">
                                    <Row style={{ paddingLeft: 12, paddingBottom: 12 }}>
                                        <b>Tipo de Atividade</b>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <LabelAndCheckbox
                                            readOnly={this.props.aguardando}
                                            gridDisplay="flex"
                                            gridJustifyContent={window.innerWidth < 400 ? "start" : "center"}
                                            gridAlignItems="end"
                                            style={{ marginLeft: 12 }}
                                            component={LabelAndCheckbox}
                                            label="Irrigante"
                                            cols="4 4 4 4"
                                            value={this.props.filtro.irrigante}
                                            onChange={() => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    irrigante: !this.props.filtro.irrigante,
                                                });
                                                this.props.getLista();
                                            }}
                                        />
                                        <LabelAndCheckbox
                                            readOnly={this.props.aguardando}
                                            gridDisplay="flex"
                                            gridJustifyContent={window.innerWidth < 400 ? "start" : "center"}
                                            gridAlignItems="end"
                                            style={{ marginLeft: 12 }}
                                            component={LabelAndCheckbox}
                                            label="Não-Irrigante"
                                            cols="8 8 8 8"
                                            value={this.props.filtro.naoIrrigante}
                                            onChange={() => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    naoIrrigante: !this.props.filtro.naoIrrigante,
                                                });
                                                this.props.getLista();
                                            }}
                                        />
                                    </Row>
                                </Grid>
                            )}
                            <Select
                                readOnly={this.props.aguardando || this.props.filtro.id_empenho}
                                options={this.listarUCsFiltradas()}
                                label="Unidades Consumidoras"
                                cols={usuarioLogadoAcessoCliente ? "12 12 12 12" : "12 12 5 5"}
                                placeholder="Selecione as UCs"
                                isMulti={true}
                                value={this.props.filtro.unidades_consumidoras}
                                onChange={(ucsSelecionadas) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        unidades_consumidoras: [...ucsSelecionadas],
                                    });
                                    this.props.getLista();
                                }}
                            />
                            {usuarioLogadoAcessoCliente ? null : (
                                <>
                                    <Select
                                        readOnly={this.props.aguardando}
                                        name="id_grupo_faturamento"
                                        options={this.props.listaGrupoFaturamento}
                                        label="Tipo de Faturamento"
                                        cols="12 12 4 4"
                                        placeholder="Selecione o tipo de faturamento"
                                        value={this.props.filtro.id_grupo_faturamento}
                                        onChange={(id_grupo_faturamento) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_grupo_faturamento,
                                            });
                                            this.props.getLista();
                                        }}
                                    />
                                    <Select
                                        readOnly={this.props.aguardando}
                                        options={[
                                            {
                                                id: 1,
                                                valor: "Todas",
                                            },
                                            {
                                                id: 2,
                                                valor: "Download Realizado",
                                            },
                                            {
                                                id: 3,
                                                valor: "Download Pendente",
                                            },
                                        ]}
                                        label="Situação do Download"
                                        cols="12 12 3 3"
                                        placeholder="Selecione a situação do download"
                                        value={this.state.situacaoDownload}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(value) => {
                                            this.setState({
                                                ...this.state,
                                                situacaoDownload: value,
                                            });
                                        }}
                                    />
                                </>
                            )}
                        </Row>
                    </ContentCardHeader>
                    <ContentCardHeader style={{ display: "flex", flexWrap: "wrap" }}>
                        <Grid cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 12 12"}>
                            {this.props.usuarioLogado && (!this.props.usuarioLogado.acesso_cliente || this.props.usuarioLogado.controla_vencimento_fatura) ? (
                                <details open={window.innerWidth >= 950} style={{ marginBottom: 20 }}>
                                    <summary>
                                        <b>
                                            Filtro por Status
                                            {window.innerWidth >= 950 || !statusAtivos ? "" : ` (${statusAtivos})`.replace(/\,/g, ", ")}
                                        </b>
                                    </summary>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            marginBottom: 20,
                                            minHeight: "fit-content",
                                        }}
                                    >
                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("#6dc9ff")} htmlFor="filtro-todas">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-todas"
                                                    label="Mostrar todos"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorBranca: e.target.checked,
                                                            statusCorAzul: e.target.checked,
                                                            statusCorAmarela: e.target.checked,
                                                            statusCorVermelha: e.target.checked,
                                                            statusCorVerde: e.target.checked,
                                                            statusImportacaoSolarVerde: false,
                                                            statusImportacaoSolarVermelha: false,
                                                        })
                                                    }
                                                    value={
                                                        this.state.statusCorBranca &&
                                                        this.state.statusCorAzul &&
                                                        this.state.statusCorAmarela &&
                                                        this.state.statusCorVermelha &&
                                                        this.state.statusCorVerde &&
                                                        !this.state.statusImportacaoSolarVerde &&
                                                        !this.state.statusImportacaoSolarVermelha
                                                    }
                                                />
                                            </label>
                                        </Grid>
                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("rgb(255 239 113)")} htmlFor="filtro-amarelas">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-amarelas"
                                                    label="Um ou dois dias para o vencimento"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorAmarela: e.target.checked,
                                                        })
                                                    }
                                                    value={this.state.statusCorAmarela}
                                                />
                                            </label>
                                        </Grid>

                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("rgb(255 101 105)")} htmlFor="filtro-vermelhas">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-vermelhas"
                                                    label="Vencendo hoje ou vencido"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorVermelha: e.target.checked,
                                                        })
                                                    }
                                                    value={this.state.statusCorVermelha}
                                                />
                                            </label>
                                        </Grid>
                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("#44ffaa")} htmlFor="filtro-verdes">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-verdes"
                                                    label="Pago"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorVerde: e.target.checked,
                                                        })
                                                    }
                                                    value={this.state.statusCorVerde}
                                                />
                                            </label>
                                        </Grid>
                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("#f9f9f9")} htmlFor="filtro-brancas">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-brancas"
                                                    label="Não importado"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorBranca: e.target.checked,
                                                        })
                                                    }
                                                    value={this.state.statusCorBranca}
                                                />
                                            </label>
                                        </Grid>
                                        <Grid
                                            cols={exibirFiltroEmpenho ? "12 12 6 6" : "12 12 6 4"}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <label style={this.getStyleSlimCard("rgb(197 255 255)")} htmlFor="filtro-azuis">
                                                <LabelAndCheckbox
                                                    style={{ justifyContent: "center" }}
                                                    readOnly={this.props.aguardando}
                                                    modoCard={true}
                                                    colorIcon="#000"
                                                    id="filtro-azuis"
                                                    label="Importado e não vencido"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            ...this.state,
                                                            statusCorAzul: e.target.checked,
                                                        })
                                                    }
                                                    value={this.state.statusCorAzul}
                                                />
                                            </label>
                                        </Grid>
                                    </div>
                                </details>
                            ) : null}
                        </Grid>
                        {exibirFiltroEmpenho ? (
                            <Grid cols="12 12 6 6" style={{ paddingTop: window.innerWidth < 768 ? 0 : 25 }}>
                                <Grid
                                    cols="12 12 12 12"
                                    style={{
                                        padding: empenhoSelecionado ? 13 : 0,
                                        textAlign: "center",
                                        minHeight: "fit-content",
                                        backgroundColor: "#BA0000",
                                        color: "#FFFFFF",
                                        borderRadius: "0.4rem",
                                        boxShadow: "#9b9b9b 0px 1px 2px 1px",
                                        fontSize: window.innerWidth < 768 ? "0.7rem" : "0.9rem",
                                    }}
                                >
                                    {empenhoSelecionado ? null : (
                                        <Row alignCenter style={{ paddingTop: 5 }}>
                                            <b
                                                style={{
                                                    padding: "4px 10px",
                                                    backgroundColor: "#ff2c2c",
                                                    borderRadius: "0.3rem",
                                                }}
                                            >
                                                (selecione um empenho para análise)
                                            </b>
                                        </Row>
                                    )}
                                    <Row alignCenter style={{ paddingTop: empenhoSelecionado ? 10 : 0 }}>
                                        <h5
                                            style={{
                                                width: "90%",
                                                borderRadius: "0.4rem",
                                                padding: 5,
                                                backgroundColor: "#EAEDEE",
                                                color: "#2B2A2A",
                                            }}
                                        >
                                            Acompanhamento de Empenho
                                        </h5>
                                    </Row>
                                    <Row>
                                        <Grid
                                            cols="8 8 8 8"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "end",
                                                textAlign: "end",
                                            }}
                                        >
                                            <b>Valor previsto para empenho:</b>
                                        </Grid>
                                        <Grid cols="4 4 4 4" style={{ textAlign: "left" }}>
                                            <b>
                                                <div
                                                    style={{
                                                        maxWidth: "9rem",
                                                        padding: 5,
                                                        borderRadius: "0.4rem",
                                                        backgroundColor: empenhoSelecionado ? "#1A9EC9" : "",
                                                    }}
                                                >
                                                    {empenhoSelecionado ? "R$ " + adicionarPontosMilhar(String(empenhoSelecionado.valor_previsto).replace(".", ",")) : "R$ 0,00 "}
                                                </div>
                                            </b>
                                        </Grid>
                                    </Row>
                                    <Row>
                                        <Grid
                                            cols="8 8 8 8"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "end",
                                                textAlign: "end",
                                            }}
                                        >
                                            <b>Valor total utilizado em todo período do empenho:</b>
                                        </Grid>
                                        <Grid cols="4 4 4 4" style={{ textAlign: "left" }}>
                                            <b>
                                                <div
                                                    style={{
                                                        maxWidth: "9rem",
                                                        padding: 5,
                                                        borderRadius: "0.4rem",
                                                        backgroundColor: empenhoSelecionado ? "#FE9A24" : "",
                                                    }}
                                                >
                                                    {empenhoSelecionado ? "R$ " + adicionarPontosMilhar(String(this.props.totalFaturaEmpenho).replace(".", ",")) : "R$ 0,00 "}
                                                </div>
                                            </b>
                                        </Grid>
                                    </Row>
                                    <Row>
                                        <Grid
                                            cols="8 8 8 8"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "end",
                                                textAlign: "end",
                                            }}
                                        >
                                            <b>Valor total do empenho disponível:</b>
                                        </Grid>
                                        <Grid cols="4 4 4 4" style={{ textAlign: "left" }}>
                                            <b>
                                                <div
                                                    style={{
                                                        maxWidth: "9rem",
                                                        padding: 5,
                                                        borderRadius: "0.4rem",
                                                        backgroundColor: !empenhoSelecionado
                                                            ? ""
                                                            : parseFloat(empenhoSelecionado.valor_previsto - this.props.totalFaturaEmpenho) >= 0
                                                            ? "#43B743"
                                                            : "#FF4141",
                                                    }}
                                                >
                                                    {empenhoSelecionado
                                                        ? "R$ " +
                                                          adicionarPontosMilhar(
                                                              String(parseFloat(empenhoSelecionado.valor_previsto - this.props.totalFaturaEmpenho)).replace(".", ",")
                                                          )
                                                        : "R$ 0,00 "}
                                                </div>
                                            </b>
                                        </Grid>
                                    </Row>
                                </Grid>
                            </Grid>
                        ) : null}
                    </ContentCardHeader>
                    <ContentCardHeader>
                        <Row>
                            <Grid cols="12 4 4 4" style={{ paddingTop: 5 }}>
                                <Button
                                    title="Imprime uma lista das faturas filtradas"
                                    disabled={this.props.aguardando}
                                    text="Imprimir Relatório"
                                    type={"primary"}
                                    event={async () => {
                                        if (!this.props.filtro.unidades_consumidoras.length && !this.props.filtro.id_cliente) {
                                            return setError("Selecione um cliente ou alguma unidade consumidora para gerar o relatório");
                                        }
                                        await this.gerarRelatorio({ statusAtivos, cabecalho, rodape, linhas });
                                    }}
                                />
                            </Grid>

                            <Grid cols="12 4 4 4" style={{ paddingTop: 5 }}>
                                <Button
                                    title="Baixa várias faturas ao mesmo tempo, conforme seleção dos filtros da tela"
                                    disabled={this.props.aguardando}
                                    text="Baixar Múltiplas"
                                    type={"primary"}
                                    event={async () => {
                                        //criar função que faz download múltiplo
                                        this.props.baixarMultiplasFaturasPdf(this.filtrarFaturasTabela(this.props), this.filtrarUCsTabela(this.props));
                                    }}
                                />
                            </Grid>
                            <Grid cols="12 4 4 4" style={{ paddingTop: 5 }}>
                                <Button
                                    title="Imprime várias faturas combinadas em um único arquivo PDF"
                                    disabled={this.props.aguardando}
                                    text="Imprimir Múltiplas"
                                    type={"primary"}
                                    event={async () => {
                                        //criar função que faz download múltiplo
                                        this.props.imprimirMultiplasFaturas(this.filtrarFaturasTabela(this.props), this.filtrarUCsTabela(this.props));
                                    }}
                                />
                            </Grid>
                        </Row>
                    </ContentCardHeader>
                    <Table responsive>
                        <THead>
                            <NewTr
                                backgroundColorTr={"#FFFFFF"}
                                backgroundColorTd={"#F5F5F5"}
                                celulas={
                                    <>
                                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter title="Status da Importação das Faturas de Energia Elétrica">
                                            Status Imp. Fatura
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={65} alignCenter title="Status da Importação de Produção de Energia Elétrica Solar">
                                            Status Imp. Prod. Solar
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter title="Saldo de Produção Solar da Fatura de Energia Elétrica">
                                            Saldo Prod. Solar
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter>
                                            Competência
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                                            Cliente
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={100} alignCenter>
                                            Nº da Nota Fiscal
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                                            UC
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter title="Grupo de Faturamento">
                                            Grupo de Faturamento
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter title="Tipo de Medição">
                                            Tipo de Medição
                                        </Th>

                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter>
                                            Data de Vencimento
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={75} alignCenter>
                                            Data de Pagamento
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={120} alignCenter>
                                            <p style={{ marginBottom: 0 }}>Usuário de Alteração</p>
                                            <p style={{ marginBottom: 0 }}>Data de Pagamento</p>
                                        </Th>

                                        <Th sticky style={{ fontSize: "0.7rem" }} minWidth={50} alignCenter>
                                            Total Fatura
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} alignCenter>
                                            PDF
                                        </Th>
                                        <Th sticky style={{ fontSize: "0.7rem" }} maxWidth={75} alignCenter>
                                            Download Realizado
                                        </Th>
                                    </>
                                }
                            />
                        </THead>
                        {!this.props.aguardando ? (
                            <TBody>
                                {this.filtrarUCsTabela(this.props).map((item) => {
                                    const nome_cliente = (this.props.listaCliente.find((cliente) => cliente.id == item.id_cliente) || {}).valor;
                                    const nome_unidade_consumidora = item.valor;
                                    const linha = (
                                        <NewTr
                                            key={`UC${item.id}`}
                                            backgroundColorTr={"#FFFFFF"}
                                            backgroundColorTd={"#F5F5F5"}
                                            style={{
                                                height: 40,
                                            }}
                                            celulas={
                                                <>
                                                    <Td>Não Importado</Td>
                                                    <Td minWidth={50} alignCenter>
                                                        {item.geracao ? (
                                                            <img alt="Unidade Consumidora Remota" height={24} style={{ marginBottom: 6 }} src={solPreto}></img>
                                                        ) : item.remota ? (
                                                            <img
                                                                title={"Unidade Consumidora Remota"}
                                                                alt="Unidade Consumidora Remota"
                                                                height={24}
                                                                style={{ marginBottom: 6 }}
                                                                src={casaPreta}
                                                            ></img>
                                                        ) : null}
                                                    </Td>
                                                    <Td
                                                        minWidth={50}
                                                        alignCenter
                                                        color={(!item.unidade_geradora && !item.unidade_remota) || parseFloat(item.saldo_geracao || 0) > 0 ? null : "#f00"}
                                                    >
                                                        {(item.saldo_geracao || 0) > 0 || item.unidade_geradora || item.unidade_remota ? Math.round(item.saldo_geracao || 0) : ""}
                                                    </Td>
                                                    <Td alignCenter>{`${this.props.filtro.mes}/${this.props.filtro.ano}`}</Td>
                                                    <Td minWidth={80}>{nome_cliente}</Td>
                                                    <Td alignCenter></Td>
                                                    <Td minWidth={120}>{nome_unidade_consumidora}</Td>
                                                    <Td alignCenter>{item.sigla_grupo_faturamento}</Td>
                                                    <Td alignCenter>{item.sigla_tipo_medicao}</Td>
                                                    <Td alignCenter></Td>
                                                    <Td alignCenter></Td>
                                                    <Td alignCenter></Td>
                                                    <Td alignCenter></Td>
                                                    <Td alignCenter></Td>
                                                    <Td alignCenter></Td>
                                                </>
                                            }
                                            botoes={[]}
                                        ></NewTr>
                                    );
                                    const totalCelulas = linha.props.celulas.props.children.length;
                                    let linhaModificada = Array.from(linha.props.celulas.props.children).splice(0, totalCelulas - 2);
                                    linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                        // 4 = cliente
                                        if (index == 4) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {nome_cliente.length > 40 ? nome_cliente.substring(0, 40) + "..." : nome_cliente}
                                                    </div>
                                                ),
                                            });
                                        }
                                        // 6 = UC
                                        if (index == 6) {
                                            return React.cloneElement(child, {
                                                children: (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {nome_unidade_consumidora.length > 45
                                                            ? `${nome_unidade_consumidora.substring(0, 45)}... (${item.nome_concessionaria})`
                                                            : `${nome_unidade_consumidora} (${item.nome_concessionaria})`}
                                                    </div>
                                                ),
                                            });
                                        }
                                        return child;
                                    });
                                    const linhaSemColunaBotoes = React.cloneElement(linha, {
                                        celulas: linhaModificada,
                                        botoes: [],
                                    });

                                    linhas.push(linhaSemColunaBotoes);

                                    return linha;
                                })}
                                {this.filtrarFaturasTabela(this.props)
                                    //ordena as faturas para aparecer primeiro as Não Importadas e depois as Validadas
                                    .sort(function (x, y) {
                                        const xFoiValidada = !(x.erros_importacao || []).length || x.importacao_validada;
                                        const yFoiValidada = !(y.erros_importacao || []).length || y.importacao_validada;
                                        return Number(xFoiValidada) - Number(yFoiValidada);
                                    })
                                    .map((item, i, array) => {
                                        const foiValidada = !(item.erros_importacao || []).length || item.importacao_validada;
                                        const brancos = !item.data_pagamento && !foiValidada && this.state.statusCorBranca;

                                        const faturaIsenta = item.importacao_validada && (item.erros_importacao || []).filter((erro) => erro.validado).length == 1;
                                        totalValorFatura += parseFloat(item.valor_fatura > 0 ? item.valor_fatura : 0);
                                        let { backgroundColorTr, backgroundColorTd } = getCorDataVencimentoFatura(item, this.props.usuarioLogado);
                                        let textColor = "#000";
                                        //se o download foi realizado diminui 50 de cada valor rgb, pra escurecer a linha
                                        if (this.validarDownloadRealizado(item)) {
                                            textColor = "#FFF";
                                            backgroundColorTr = `rgb(${backgroundColorTr.match(/\d+/g).map((v) => (parseInt(v) >= 90 ? parseInt(v) - 90 : 0))})`;
                                            backgroundColorTd = `rgb(${backgroundColorTd.match(/\d+/g).map((v) => (parseInt(v) >= 90 ? parseInt(v) - 90 : 0))})`;
                                        }
                                        const botoes = [];
                                        if (!brancos) {
                                            if (faturaIsenta) {
                                                botoes.push({
                                                    children: (
                                                        <span
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "0.3rem",
                                                                backgroundColor: "#fff689",
                                                                boxShadow: "#c9c9c9 0px 1px 9px 1px",
                                                                padding: 5,
                                                            }}
                                                        >
                                                            Fatura Isenta
                                                        </span>
                                                    ),
                                                });
                                            } else {
                                                if (item.data_pagamento) {
                                                    botoes.push({
                                                        title: "Remover data de pagamento",
                                                        type: "danger",
                                                        disabled: !this.props.usuarioLogado.acesso_fatura_data_pagamento_editar,
                                                        event: () => {
                                                            const registro = {
                                                                dados_fatura: {
                                                                    competencia: String(item.competencia).substring(4) + "/" + String(item.competencia).substring(0, 4),
                                                                    nome_cliente: item.nome_cliente,
                                                                    nome_unidade_consumidora: item.nome_unidade_consumidora,
                                                                    data_vencimento: item.data_vencimento ? String(item.data_vencimento).substring(0, 10) : null,
                                                                },
                                                                id: item.id,
                                                                id_cliente: item.id_cliente,
                                                                id_unidade_consumidora: item.id_unidade_consumidora,
                                                                data_pagamento: item.data_pagamento ? String(item.data_pagamento).substring(0, 10) : null,
                                                                comprovantes_pagamento: JSON.parse(item.comprovantes_pagamento),
                                                                id_usuario_data_pagamento: this.props.usuarioLogado.id,
                                                            };
                                                            //define a data atual
                                                            this.props.setModoTela("excluirDataPagamento", registro);
                                                            this.props.initForm(registro);
                                                            //this.props.setDataPagamento(item);
                                                        },
                                                        children: (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    flexWrap: "noWrap",
                                                                }}
                                                            >
                                                                <i className="fas fa-comment-dollar"></i>
                                                                <i className="fas fa-times" style={{ fontSize: "0.6rem" }}></i>
                                                            </div>
                                                        ),
                                                    });
                                                } else {
                                                    botoes.push({
                                                        title: "Adicionar data de pagamento",
                                                        type: "success",
                                                        icon: "fas fa-comment-dollar",
                                                        disabled: !this.props.usuarioLogado.acesso_fatura_data_pagamento_editar,
                                                        event: () => {
                                                            const registro = {
                                                                dados_fatura: {
                                                                    competencia: String(item.competencia).substring(4) + "/" + String(item.competencia).substring(0, 4),
                                                                    nome_cliente: item.nome_cliente,
                                                                    nome_unidade_consumidora: item.nome_unidade_consumidora,
                                                                    data_vencimento: item.data_vencimento ? String(item.data_vencimento).substring(0, 10) : null,
                                                                },
                                                                id: item.id,
                                                                id_cliente: item.id_cliente,
                                                                id_unidade_consumidora: item.id_unidade_consumidora,
                                                                data_pagamento: new Date().toISOString().substring(0, 10),
                                                                comprovantes_pagamento: JSON.parse(item.comprovantes_pagamento),
                                                                id_usuario_data_pagamento: this.props.usuarioLogado.id,
                                                            };
                                                            //define a data atual
                                                            this.props.setModoTela("alterarDataPagamento", registro);
                                                            this.props.initForm(registro);
                                                            //this.props.setDataPagamento(item);
                                                        },
                                                    });
                                                }
                                                if (item.importado || item.arquivo_pdf) {
                                                    botoes.push({
                                                        style: {
                                                            width: 38,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        },
                                                        title: "Visualizar",
                                                        type: "primary",
                                                        icon: "fas fa-eye",
                                                        event: () => {
                                                            this.props.setModoTela("cadastro", {
                                                                ...item,
                                                                competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            });
                                                            this.props.initForm({
                                                                ...item,
                                                                competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
                                                            });
                                                        },
                                                    });
                                                }
                                            }
                                        }
                                        const linha = (
                                            <NewTr
                                                style={{ color: textColor }}
                                                backgroundColorTr={backgroundColorTr}
                                                backgroundColorTd={backgroundColorTd}
                                                key={item.id}
                                                celulas={
                                                    <>
                                                        <Td backgroundColor={backgroundColorTd} minWidth={50}>
                                                            {(item.erros_importacao || []).length && !item.importacao_validada ? "Não Importado" : "Validado"}
                                                        </Td>
                                                        <Td minWidth={50} alignCenter>
                                                            {item.unidade_geradora ? (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                    title={parseFloat(item.quantidade_geracao || 0) <= 0 ? "Importação Solar Pendente" : "Geração Solar Importada"}
                                                                >
                                                                    {parseFloat(item.quantidade_geracao || 0) <= 0 ? (
                                                                        <img
                                                                            alt=""
                                                                            height={24}
                                                                            style={{
                                                                                marginBottom: 6,
                                                                                marginRight: 3,
                                                                            }}
                                                                            src={solVermelho}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            alt=""
                                                                            height={24}
                                                                            style={{
                                                                                marginBottom: 6,
                                                                                marginRight: 3,
                                                                            }}
                                                                            src={solVerde}
                                                                        />
                                                                    )}
                                                                    <span> - FV{item.sequencia_uc_geradora}</span>
                                                                </div>
                                                            ) : item.unidade_remota ? (
                                                                <div
                                                                    title={"Unidade Consumidora Remota"}
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <img alt="" height={24} style={{ marginBottom: 6, marginRight: 3 }} src={casaPreta}></img>
                                                                    <span> - FV{item.sequencia_uc_geradora}</span>
                                                                </div>
                                                            ) : null}
                                                        </Td>
                                                        <Td
                                                            backgroundColor={backgroundColorTd}
                                                            minWidth={50}
                                                            alignCenter
                                                            color={(!item.unidade_geradora && !item.unidade_remota) || parseFloat(item.saldo_geracao || 0) > 0 ? null : "#b90000"}
                                                        >
                                                            {(item.saldo_geracao || 0) > 0 || item.unidade_geradora || item.unidade_remota
                                                                ? Math.round(item.saldo_geracao || 0)
                                                                : ""}
                                                        </Td>
                                                        <Td minWidth={50} alignCenter>{`${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(
                                                            0,
                                                            4
                                                        )}`}</Td>
                                                        <Td backgroundColor={backgroundColorTd} minWidth={80}>
                                                            {item.nome_cliente}
                                                        </Td>
                                                        <Td minWidth={150}>
                                                            {item.numero_nota_fiscal != null ? (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        color: "#000",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontSize: "0.8rem",
                                                                            width: 130,
                                                                            background: "#fff",
                                                                            padding: "6px  3px",
                                                                            borderRadius: "5px",
                                                                            border: "1px solid #ddd",
                                                                        }}
                                                                    >
                                                                        {`${item.numero_nota_fiscal}${item.serie_nota_fiscal ? " - " + item.serie_nota_fiscal : ""}`}
                                                                    </div>

                                                                    <button
                                                                        title="Copiar conteúdo"
                                                                        onClick={() =>
                                                                            this.copiarTextoClipboard(
                                                                                `${item.numero_nota_fiscal}${item.serie_nota_fiscal ? " - " + item.serie_nota_fiscal : ""}`
                                                                            )
                                                                        }
                                                                        style={{
                                                                            marginLeft: -30,
                                                                            backgroundColor: "#fff",
                                                                            color: "#555",
                                                                            height: 29,
                                                                            border: "1px solid #999",
                                                                            borderRadius: 5,
                                                                            outline: "none",
                                                                        }}
                                                                    >
                                                                        <i className="far fa-clone"></i>
                                                                    </button>
                                                                </div>
                                                            ) : null}
                                                        </Td>
                                                        <Td backgroundColor={backgroundColorTd} minWidth={120}>
                                                            {item.nome_unidade_consumidora}
                                                            {" - "}
                                                            {item.nome_concessionaria}
                                                        </Td>

                                                        <Td alignCenter>{item.sigla_grupo_faturamento}</Td>
                                                        <Td backgroundColor={backgroundColorTd} alignCenter>
                                                            {item.sigla_tipo_medicao}
                                                        </Td>
                                                        <Td minWidth={50} alignCenter>
                                                            {DateFormat.formatarDataSqlParaTela(item.data_vencimento)}
                                                        </Td>
                                                        <Td backgroundColor={backgroundColorTd} minWidth={100} alignCenter>
                                                            {faturaIsenta ? (
                                                                <span
                                                                    style={{
                                                                        borderRadius: "0.3rem",
                                                                        backgroundColor: "#fff689",
                                                                        boxShadow: "#c9c9c9 0px 1px 9px 1px",
                                                                        padding: 5,
                                                                    }}
                                                                >
                                                                    Fatura Isenta
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    {item.nome_usuario_data_pagamento && !item.data_pagamento
                                                                        ? "Removida"
                                                                        : DateFormat.formatarDataSqlParaTela(item.data_pagamento)}
                                                                </>
                                                            )}
                                                        </Td>
                                                        <Td minWidth={120} alignCenter>
                                                            {faturaIsenta ? (
                                                                "Definida pelo sistema"
                                                            ) : (
                                                                <>
                                                                    <p style={{ margin: 0 }}>
                                                                        {!item.data_pagamento ? item.nome_usuario_data_pagamento : null}
                                                                        {item.data_pagamento && (item.nome_usuario_data_pagamento || "Definida pelo sistema")}
                                                                    </p>
                                                                    <p style={{ margin: 0 }}>
                                                                        {item.datahora_alteracao_data_pagamento &&
                                                                            DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao_data_pagamento)}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </Td>
                                                        <Td backgroundColor={backgroundColorTd} minWidth={50} alignCenter>
                                                            {adicionarPontosMilhar(item.valor_fatura || 0)}
                                                        </Td>
                                                        <Td alignCenter minWidth={50}>
                                                            {item.arquivo_pdf &&
                                                            (!(item.erros_importacao || []).length || item.importacao_validada || this.props.usuarioLogado.administrador) ? (
                                                                <ButtonTable
                                                                    style={{ margin: 0 }}
                                                                    title="Visualizar PDF"
                                                                    type={"primary"}
                                                                    icon={"fas fa-file-pdf"}
                                                                    visible={true}
                                                                    event={() => {
                                                                        if (item.id_tipo_mercado == 2) {
                                                                            return this.setState({ ...this.state, exibirModalSelecaoFatura: true, itemPdfSelecionado: item });
                                                                        }
                                                                        this.props.buscaPdf(
                                                                            item.id,
                                                                            `UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(item.competencia).substring(
                                                                                4,
                                                                                6
                                                                            )}-${String(item.competencia).substring(0, 4)}`
                                                                        );
                                                                        this.props.setArquivoPDF();
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </Td>
                                                        <Td backgroundColor={backgroundColorTd} alignCenter minWidth={50}>
                                                            {item.arquivo_pdf &&
                                                            this.validarDownloadRealizado(item) &&
                                                            (!(item.erros_importacao || []).length || item.importacao_validada || this.props.usuarioLogado.administrador) ? (
                                                                <ButtonTable
                                                                    style={{
                                                                        margin: 0,
                                                                        backgroundColor: "#28A745",
                                                                        color: "#FFFFFF",
                                                                        border: "1px solid #FFF",
                                                                    }}
                                                                    title='Alterar para "Download Pendente"'
                                                                    icon={"fas fa-check-square"}
                                                                    iconHover={"fas fa-times"}
                                                                    backgroundColorHover="#fc2828"
                                                                    colorHover="white"
                                                                    visible={true}
                                                                    event={() => this.props.excluirDownloadRealizado(item.id)}
                                                                />
                                                            ) : null}
                                                        </Td>
                                                    </>
                                                }
                                                botoes={botoes}
                                            ></NewTr>
                                        );

                                        const totalCelulas = linha.props.celulas.props.children.length;
                                        let linhaModificada = Array.from(linha.props.celulas.props.children).splice(0, totalCelulas - 2);
                                        linhaModificada = React.Children.map(linhaModificada, (child, index) => {
                                            // 4 = cliente
                                            if (index == 4) {
                                                return React.cloneElement(child, {
                                                    children: (
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {item.nome_cliente.length > 40 ? item.nome_cliente.substring(0, 40) + "..." : item.nome_cliente}
                                                        </div>
                                                    ),
                                                });
                                            }
                                            // 5 = numero nota serie_nota_fiscal
                                            if (index == 5) {
                                                return React.cloneElement(child, {
                                                    children: (
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {item.numero_nota_fiscal != null && item.serie_nota_fiscal != null
                                                                ? item.numero_nota_fiscal + "-" + item.serie_nota_fiscal
                                                                : " - "}
                                                        </div>
                                                    ),
                                                });
                                            }
                                            // 6 = UC
                                            if (index == 6) {
                                                const nome_unidade_consumidora = item.nome_unidade_consumidora;
                                                return React.cloneElement(child, {
                                                    children: (
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {nome_unidade_consumidora.length > 45
                                                                ? `${nome_unidade_consumidora.substring(0, 45)}... (${item.nome_concessionaria})`
                                                                : `${nome_unidade_consumidora} (${item.nome_concessionaria})`}
                                                        </div>
                                                    ),
                                                });
                                            }
                                            return child;
                                        });
                                        const linhaSemColunaBotoes = React.cloneElement(linha, {
                                            celulas: linhaModificada,
                                            botoes: [],
                                        });

                                        linhas.push(linhaSemColunaBotoes);

                                        //ao chegar na última iteração constrói o rodapé
                                        if (i === array.length - 1) {
                                            rodape = this.renderizarRodape({
                                                totalValorFatura,
                                            });
                                        }
                                        return linha;
                                    })}

                                {rodape}
                            </TBody>
                        ) : null}
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
    async gerarRelatorio({ statusAtivos, cabecalho, rodape, linhas }) {
        this.props.setAguardando(true);
        const ucsSelecionadas = this.props.listaUnidadeConsumidora.filter((item) => this.props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id));
        const nomesUcsSelecionadas = String(ucsSelecionadas.map((item) => item.valor));
        const totalCelulas = cabecalho.props.celulas.props.children.length;
        const linhaModificada = Array.from(cabecalho.props.celulas.props.children).splice(0, totalCelulas - 2);

        const cabecalhoSemColunasBotoes = (
            <NewTr
                celulas={linhaModificada.map((el, i) => {
                    const copia = React.cloneElement(
                        el,
                        {
                            key: i,
                            sticky: false,
                            style: { fontSize: "0.85rem", paddingTop: 30 },
                        },
                        [...el.props.children]
                    );
                    return copia;
                })}
            />
        );
        const filtro = (
            <b style={{ fontSize: "0.8rem" }}>
                Cliente:
                {` ${(this.props.listaCliente.find((cliente) => cliente.id == this.props.filtro.id_cliente) || {}).valor || "Todos"} - `}
                UCs:
                {` ${nomesUcsSelecionadas.length ? nomesUcsSelecionadas : "Todas"} - `}
                Ano:{" " + (this.props.filtro.ano || "") + " - "}
                Mês:
                {` ${(this.props.listaMeses.find((mes) => mes.id == this.props.filtro.mes) || {}).valor || "Todos"} - `}
                Filtro por Status: {String(statusAtivos).replace(/\,/g, ", ")}
            </b>
        );
        const pdfBase64 = await htmlToPdf(
            <TabelaFaturaClientePDF
                dadosImpressao={{
                    rodape,
                    linhas,
                    filtro,
                    cabecalhoSemColunasBotoes,
                }}
            />,
            this.props.setAguardando
        );
        this.props.setArquivoPDF(pdfBase64, "Relatório faturas.pdf", "landscape");
    }

    renderizarRodape({ totalValorFatura }) {
        const rodape = (
            <Tr>
                <Td colSpan={12} fontWeight="bold">
                    Total
                </Td>

                <Td alignRight fontWeight="bold">
                    {parseFloat(totalValorFatura || 0)
                        .toFixed(0)
                        .replace(".", ",")}
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
            </Tr>
        );
        return rodape;
    }
}

const mapStateToProps = (state) => {
    return {
        sessao: state.auth.usuario,
        usuarioLogado: state.auth.usuarioLogado,
        modoTela: state.fatura.modoTela,
        aguardando: state.fatura.aguardando,
        filtro: state.fatura.filtro,
        lista: state.fatura.lista,
        listaCliente: state.fatura.listaCliente,
        listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
        listaProdutoTipo: state.fatura.listaProdutoTipo,
        listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
        listaEmpenho: state.empenho.lista,
        listaMeses: state.fatura.listaMeses,
        totalFaturaEmpenho: state.fatura.totalFaturaEmpenho,
        pdf: state.displayPDF.pdf,
        id_pdf: state.displayPDF.id_pdf,
        nomePdf: state.displayPDF.nomePdf,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            setFiltro,
            salvar,
            salvarMultiplos,
            excluir,
            getLista,
            getListaEmpenho,
            reprocessar,
            reprocessarTodas,
            buscaPdf,
            setArquivoPDF,
            incluirDownloadRealizado,
            excluirDownloadRealizado,
            setAguardando,
            executarManutencao,
            setDataPagamento,
            getListaGrupoFaturamento,
            resetarFiltro,
            baixarMultiplasFaturasPdf,
            imprimirMultiplasFaturas,
            getTotalFaturaEmpenho,
            buscarPdfFaturaAdicional,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaVisualizacao);
