import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import NumberFormat from "react-number-format";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    getDemanda,
    getListaGraficoConsumoAnual,
    getListaGraficoCustoAnual,
    getListaGraficoTarifaMedia,
    getListaGraficoTarifaMediaGeral,
    getListaCustoPorUnidadePeriodo,
    getListaGraficoCustoPorUnidadeMensal,
    getListaGraficoCustoPorUnidadeAcumuladoAno,
    getListaGraficoCustoPorUnidadeAnual,
    setFiltro,
} from "../analiseActions";
import { montarGraficoLineMensal } from "../../common/graficos/montarGraficoLineMensal.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";

function analiseCustoConsumo(props) {
    const {
        state,
        listar,
        contratoDemanda,
        listaGraficoCustoAnual,
        listaGraficoConsumoAnual,
        listaGraficoTarifaMedia,
        listaGraficoTarifaMediaGeral,
        listaGraficoCustoPorUnidadeAcumuladoAno,
        listaUnidadeConsumidora,
        listaGraficoCustoUnidadeAnual,
        listaGraficoCustoUnidadeMensal,
        listaCustoPorUnidadePeriodo,
        filtro,
        getDemanda,
        getListaGraficoConsumoAnual,
        getListaGraficoCustoAnual,
        getListaGraficoTarifaMedia,
        getListaGraficoTarifaMediaGeral,
        getListaCustoPorUnidadePeriodo,
        getListaGraficoCustoPorUnidadeMensal,
        getListaGraficoCustoPorUnidadeAcumuladoAno,
        getListaGraficoCustoPorUnidadeAnual,
        setAguardando,
        setFiltro,
    } = props;

    const [graficoCustoAnual, setGraficoCustoAnual] = useState(null);
    const [graficoCustoMensal, setGraficoCustoMensal] = useState(null);
    const [graficoConsumoAnual, setGraficoConsumoAnual] = useState(null);
    const [graficoConsumoMensal, setGraficoConsumoMensal] = useState(null);
    const [graficoCustoUnidadeAnual, setGraficoCustoUnidadeAnual] = useState(null);
    const [graficoCustoUnidadeMensal, setGraficoCustoUnidadeMensal] = useState(null);
    const [graficoTarifaMediaMensal, setGraficoTarifaMediaMensal] = useState(null);
    const [graficoTarifaMediaMensalGeral, setGraficoTarifaMediaMensalGeral] = useState(null);
    const [graficoCustoPorUnidadeAcumuladoAno, setGraficoCustoPorUnidadeAcumuladoAno] = useState(null);
    const [listaConsumoEditavel, setListaConsumoEditavel] = useState([]);
    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getDemanda(),
                getListaGraficoConsumoAnual(),
                getListaGraficoCustoAnual(),
                getListaGraficoTarifaMedia(),
                getListaGraficoTarifaMediaGeral(),
                getListaCustoPorUnidadePeriodo(),
                getListaGraficoCustoPorUnidadeMensal(),
                getListaGraficoCustoPorUnidadeAcumuladoAno(),
                getListaGraficoCustoPorUnidadeAnual(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoAnual();
        atualizarListaGraficoConsumoAnual();
        atualizarListaGraficoCustoPorUnidadeAnual();
        atualizarListaGraficoCustoPorUnidadeMensal();
        atualizarListaGraficoTarifaMedia();
        atualizarListaGraficoTarifaMediaGeral();
        atualizarListaGraficoCustoPorUnidadeAcumuladoAno();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoAnual) graficoCustoAnual.dispose();
        if (graficoCustoMensal) graficoCustoMensal.dispose();
        if (graficoConsumoAnual) graficoConsumoAnual.dispose();
        if (graficoConsumoMensal) graficoConsumoMensal.dispose();
        if (graficoCustoUnidadeAnual) graficoCustoUnidadeAnual.dispose();
        if (graficoCustoUnidadeMensal) graficoCustoUnidadeMensal.dispose();
        if (graficoTarifaMediaMensal) graficoTarifaMediaMensal.dispose();
        if (graficoTarifaMediaMensalGeral) graficoTarifaMediaMensalGeral.dispose();
        if (graficoCustoPorUnidadeAcumuladoAno) graficoCustoPorUnidadeAcumuladoAno.dispose();
    }

    function atualizarListaGraficoCustoAnual() {
        const graficoCustoAnualAtualizado = montarGraficoClusteredColumnAnual(state, graficoCustoAnual, "graficoCustoAnual", listaGraficoCustoAnual, "Custo Total (R$)", "R$", "");

        setGraficoCustoAnual(graficoCustoAnualAtualizado);

        const graficoCustoMensalAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoCustoMensal,
            "graficoCustoMensal",
            listaGraficoCustoAnual,
            "Custo Mensal (R$)",
            "R$",
            ""
        );
        setGraficoCustoMensal(graficoCustoMensalAtualizado);
    }

    function atualizarListaGraficoConsumoAnual() {
        const graficoConsumoAnualAtualizado = montarGraficoClusteredColumnAnual(
            state,
            graficoConsumoAnual,
            "graficoConsumoAnual",
            listaGraficoConsumoAnual,
            "Consumo Total (kWh)",
            "",
            "kWh"
        );

        setGraficoConsumoAnual(graficoConsumoAnualAtualizado);

        //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
        setListaConsumoEditavel([...listaGraficoConsumoAnual]);

        const graficoConsumoMensalAtualizado = montarGraficoLineMensal(
            state,
            graficoConsumoMensal,
            "graficoConsumoMensal",
            listaGraficoConsumoAnual,
            "Consumo Mensal (kWh)",
            "",
            "kWh"
        );

        setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAnual() {
        const graficoCustoUnidadeAnualAtualizado = montarGraficoClusteredColumnGrupo(
            state,
            graficoCustoUnidadeAnual,
            "graficoCustoUnidadeAnual",
            listaGraficoCustoUnidadeAnual.filter(
                (item) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == item.agrupador)
            ),
            "Custo Total por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeAnual(graficoCustoUnidadeAnualAtualizado);
    }
    function atualizarListaGraficoCustoPorUnidadeMensal() {
        const graficoCustoUnidadeMensalAtuaizado = montarGraficoClusteredColumnMensal(
            state,
            graficoCustoUnidadeMensal,
            "graficoCustoUnidadeMensal",
            listaGraficoCustoUnidadeMensal.map((item) => ({
                mes: item.mes,
                anos: item.tipos
                    .filter((tipo) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == tipo.tipo))
                    .map((tipo) => ({
                        ano: tipo.tipo.substring(0, 20) + (tipo.tipo.length > 20 ? "..." : ""),
                        valor: tipo.valor || 0,
                    })),
            })),
            "Custo Mensal por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeMensal(graficoCustoUnidadeMensalAtuaizado);
    }

    function atualizarListaGraficoTarifaMedia() {
        const graficoTarifaMediaMensalAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoTarifaMediaMensal,
            "graficoTarifaMediaMensal",
            listaGraficoTarifaMedia,
            "Tarifa Média Líquida - TML (Sem Demanda e Reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensal(graficoTarifaMediaMensalAtualizado);
    }

    function atualizarListaGraficoTarifaMediaGeral() {
        const graficoTarifaMediaMensalGeralAtualizado = montarGraficoClusteredColumnMensal(
            state,
            graficoTarifaMediaMensalGeral,
            "graficoTarifaMediaMensalGeral",
            listaGraficoTarifaMediaGeral,
            "Tarifa Média Bruta - TMB (Com Demanda e Reativos) (R$)",
            "R$",
            ""
        );
        setGraficoTarifaMediaMensalGeral(graficoTarifaMediaMensalGeralAtualizado);
    }
    function atualizarListaGraficoCustoPorUnidadeAcumuladoAno() {
        const graficoCustoPorUnidadeAcumuladoAnoAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoPorUnidadeAcumuladoAno,
            chartDivId: "graficoCustoPorUnidadeAcumuladoAno",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoCustoPorUnidadeAcumuladoAno].sort((a, b) => a.valor - b.valor),
            titulo: "Custo Acumulado Anual por UC (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoPorUnidadeAcumuladoAno(graficoCustoPorUnidadeAcumuladoAnoAtualizado);
    }

    function renderizarCelulasComZeros(corCiclo,listaDados) {
        let listaMeses = [];
        for (let i = listaDados.length; i < 12; i++) {
            listaMeses.push(i);
        }

        return listaMeses.map((mes) => (
            <Td key={mes} alignCenter color="#fff"  backgroundColor={corCiclo}>
                0
            </Td>
        ));
    }

    function renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal }) {
        return (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                    }}
                >
                    Janeiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                    }}
                >
                    Fevereiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                    }}
                >
                    Março
                    {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                    }}
                >
                    Abril
                    {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                    }}
                >
                    Maio
                    {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                    }}
                >
                    Junho
                    {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                    }}
                >
                    Julho
                    {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                    }}
                >
                    Agosto
                    {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                    }}
                >
                    Setembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                    }}
                >
                    Outubro
                    {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                    }}
                >
                    Novembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                    }}
                >
                    Dezembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                </Th>
            </>
        );
    }


    let ucFiltradaSazonal = false;
    let demandaAnoInicial = "";
    let demandaMesInicial = "";
    let demandaAnoFinal = "";
    let demandaMesFinal = "";
    let demandaCompetenciaInicial = "";
    let demandaCompetenciaFinal = "";
    let primeiroMesNaoSazonal = "";

    const mesAtualDate = new Date().getMonth() + 1;
    const anoAtual = String(new Date().getFullYear());
    if (contratoDemanda) {
        primeiroMesNaoSazonal = ucFiltradaSazonal ? "" : String(contratoDemanda.competencia_base_sazonalidade).substring(4, 6);
        //se é não sazonal, pega os últimos 12 meses,

        const mesAtual = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesAtual) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesAtual) + 1 > 12 ? "01" : Number(mesAtual) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade =
            contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaAnoInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(0, 4) : String(competenciaBaseSazonalidade).substring(0, 4);
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaAnoFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(0, 4) : anoAtual;
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesAtual;
        demandaCompetenciaInicial = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_inicio) : competenciaBaseSazonalidade; //usado apenas no calculo de sazonalidade de uc Nao Sazonal
        demandaCompetenciaFinal = ucFiltradaSazonal ? parseInt(contratoDemanda.competencia_fim) : parseInt(`${anoAtual}${mesAtual}`);
    }

    let corCiclo = "#30303d";

    let anosCusto = [];
    listaGraficoCustoAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosCusto.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosCusto.push(ano.ano);
            }
        });
    });

    anosCusto = anosCusto.sort((a, b) => a - b);

    let anosConsumo = [];
    listaGraficoConsumoAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            const anoString = String(ano.ano);
            if (!anosConsumo.includes(anoString)) {
                anosConsumo.push(anoString);
            }
        });
    });


    if (demandaAnoInicial && !anosConsumo.includes(demandaAnoInicial)) {
        anosConsumo.push(demandaAnoInicial);
    }

    if (demandaAnoFinal && !anosConsumo.includes(demandaAnoFinal)) {
        anosConsumo.push(demandaAnoFinal);
    }
    anosConsumo = anosConsumo.sort((a, b) => a - b);

    let anosTarifa = [];
    listaGraficoTarifaMedia.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosTarifa.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosTarifa.push(ano.ano);
            }
        });
    });

    anosTarifa = anosTarifa.sort((a, b) => a - b);

    let anosTarifaGeral = [];
    listaGraficoTarifaMediaGeral.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosTarifaGeral.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosTarifaGeral.push(ano.ano);
            }
        });
    });

    anosTarifaGeral = anosTarifaGeral.sort((a, b) => a - b);

    let valorUnidadeAnualTotal = 0;
    listaGraficoCustoPorUnidadeAcumuladoAno.forEach((item) => {
        valorUnidadeAnualTotal = parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
    });

    return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                </Tr>
                            </THead>
                            <TBody>
                                {anosCusto.map((ano) => {
                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoCustoAnual.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {renderizarCelulasComZeros(corCiclo,listaGraficoCustoAnual)}
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoConsumoMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Sazonalidade</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosConsumo.map((ano) => (
                                    <Tr key={ano}>
                                        <Td color="#fff">{ano}</Td>
                                        {listaGraficoConsumoAnual.map((mes) => {
                                              const competenciaLinha = parseInt(`${ano}${mes.mes}`);
                                            const propsInicioNaoSazonalidade = {
                                                title: mes.mes == primeiroMesNaoSazonal && demandaAnoInicial == ano && !ucFiltradaSazonal ? "Primeiro mês Não Sazonal" : "",
                                                style: {
                                                    border: mes.mes == primeiroMesNaoSazonal && demandaAnoInicial == ano && !ucFiltradaSazonal ? "3px solid #00ff51" : null,
                                                },
                                            };
                                          
                                            if (filtro.unidades_consumidoras.length == 1) {
                                                if (ucFiltradaSazonal) {
                                                    corCiclo = mes.mes == demandaMesInicial ? (demandaAnoInicial == ano ? "#595967" : "#30303d") : corCiclo;
                                                } else {
                                                    corCiclo = competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal ? "#595967" : "#30303d";
                                                }
                                            } else {
                                                corCiclo = "#30303d";
                                            }
                                            //mes.anos = [{ano: 2020, mes: '12', valor: 2443.4},...]
                                            const anoMes = mes.anos.find((anoMes) => anoMes.ano == ano);
                                           
                                            if (anoMes) {
                                               
                                             
                                                if (anoMes.digitado || (competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal && !anoMes.valor)) {
                                                     return (
                                                        <Td {...propsInicioNaoSazonalidade} key={`val${mes.mes}${anoMes.ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                            <NumberFormat
                                                                className="form-control"
                                                                style={{
                                                                    textAlign: "center",
                                                                    maxWidth: 80,
                                                                    height: 20,
                                                                    fontSize: 13,
                                                                }}
                                                                placeholder={"0"}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                fixedDecimalScale={2}
                                                                value={String(anoMes.valor || "0,00").replace(".", ",")}
                                                                onChange={(data) => {
                                                                    let lista = listaGraficoConsumoAnual.map((item) => {
                                                                        return {
                                                                            mes: item.mes,
                                                                            anos: item.anos.map((itemAno) => {
                                                                                if (mes.mes == item.mes && itemAno.ano == anoMes.ano) {
                                                                                    return {
                                                                                        ...itemAno,
                                                                                        valor: parseFloat(data.target.value.replace(",", ".")),
                                                                                    };
                                                                                } else {
                                                                                    return itemAno;
                                                                                }
                                                                            }),
                                                                        };
                                                                    });
                                                                    //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
                                                                    setListaConsumoEditavel([...lista]);

                                                                    getListaGraficoConsumoAnual(lista);

                                                                    const graficoConsumoMensalAtualizado = montarGraficoLineMensal(
                                                                        state,
                                                                        graficoConsumoMensal,
                                                                        "graficoConsumoMensal",
                                                                        lista,
                                                                        "Consumo Mensal (kWh)",
                                                                        "",
                                                                        "kWh"
                                                                    );
                                                                    setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
                                                                }}
                                                            />
                                                        </Td>
                                                    );
                                                   
                                                } else {
                                                    return (
                                                        <Td {...propsInicioNaoSazonalidade} key={`${mes.mes}${anoMes.ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                            {parseFloat(anoMes.valor || 0)
                                                                .toFixed(0)
                                                                .replace(".", ",")}
                                                        </Td>
                                                    );
                                                }
                                                // });
                                            } else {
                                                if ((competenciaLinha >= demandaCompetenciaInicial && competenciaLinha <= demandaCompetenciaFinal)) {
                                                    return (
                                                        <Td {...propsInicioNaoSazonalidade} key={`dig${mes.mes}${ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                            <NumberFormat
                                                                className="form-control"
                                                                style={{
                                                                    textAlign: "center",
                                                                    maxWidth: 80,
                                                                    height: 20,
                                                                    fontSize: 13,
                                                                }}
                                                                placeholder={"0"}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                fixedDecimalScale={2}
                                                                value={String(0 || "0,00").replace(".", ",")}
                                                                onChange={(data) => {
                                                                    let lista = listaGraficoConsumoAnual.map((item) => {
                                                                        if (item.mes != mes.mes) {
                                                                            return item;
                                                                        } else {
                                                                            let anosAux = item.anos.map((itemAnoAux) => itemAnoAux);
                                                                            anosAux.push({
                                                                                ano: ano,
                                                                                mes: item.mes,
                                                                                valor: parseFloat(data.target.value.replace(",", ".")),
                                                                                digitado: true,
                                                                            });

                                                                            return {
                                                                                ...item,
                                                                                anos: anosAux,
                                                                            };
                                                                        }
                                                                    });

                                                                     //atualiza a lista quando altera os valores, para recalcular a sazonalidade com os valores simulados
                                                                    setListaConsumoEditavel([...lista]);


                                                                    getListaGraficoConsumoAnual(lista);

                                                                    const graficoConsumoMensalAtualizado = montarGraficoLineMensal(
                                                                        state,
                                                                        graficoConsumoMensal,
                                                                        "graficoConsumoMensal",
                                                                        lista,
                                                                        "Consumo Mensal (kWh)",
                                                                        "",
                                                                        "kWh"
                                                                    );
                                                                    setGraficoConsumoMensal(graficoConsumoMensalAtualizado);
                                                                }}
                                                            />
                                                        </Td>
                                                    );
                                                } else {
                                                    return (
                                                        <Td {...propsInicioNaoSazonalidade} key={`dig${mes.mes}${ano}`} alignCenter backgroundColor={corCiclo} color="#fff">
                                                            0
                                                        </Td>
                                                    );
                                                    
                                                }
                                            }
                                        })}
                                        {renderizarCelulasComZeros(corCiclo,listaGraficoConsumoAnual)}
                                        <Td
                                            style={{ display: "flex", justifyContent: "space-around" }}
                                            color="#fff"
                                            backgroundColor={demandaAnoFinal == ano ? "#005662" : "#40838f"}
                                        >
                                            {contratoDemanda
                                                ? (() => {
                                                      let listaSazonalidade = [];
                                                      //altera o calculo conforme edita os valores
                                                      listaConsumoEditavel.forEach((item) => {
                                                          item.anos.forEach((itemMes) => {
                                                              listaSazonalidade.push({
                                                                  competencia: parseInt(`${itemMes.ano}${itemMes.mes}`),
                                                                  valor: itemMes.valor,
                                                              });
                                                          });
                                                      });
                                                      listaSazonalidade.sort((a, b) => a.competencia - b.competencia);

                                                      //SAZONALIADE = (SOMA DOS QUATRO MENORES) DIVIDIDO (SOMA DOS QUATRO MAIORES)
                                                      if (contratoDemanda) {
                                                          //não sazonal só calcula pro ano atual
                                                          if (!ucFiltradaSazonal && anoAtual != ano) return "-";

                                                          let demandaInicio = parseInt(`${demandaMesInicial == "01" ? ano : ano - 1}${demandaMesInicial}`);
                                                          let demandaFim = parseInt(`${ano}${demandaMesFinal}`);

                                                          //se for não sazonal altera o inicio e fim
                                                          if (!ucFiltradaSazonal && anoAtual == ano) {
                                                              demandaInicio = demandaCompetenciaInicial;
                                                              demandaFim = demandaCompetenciaFinal;
                                                          }

                                                          //sefor de 01/22 a 12/22 mostra 2022, se for 04/22 a 03/23 mostra 22/23
                                                          const ciclo =
                                                              demandaAnoInicial == demandaAnoFinal
                                                                  ? `${ano}`
                                                                  : `${demandaMesInicial}/${String(ano - 1)} à ${demandaMesFinal}/${String(ano)}`;

                                                          //se o for não sazonal, sobreescreve o demandaInicio e demandaFim

                                                          //separa só as 12 que devem ser consideradas
                                                          listaSazonalidade = listaSazonalidade.filter(
                                                              (item) => item.competencia >= demandaInicio && item.competencia <= demandaFim && item.valor > 0
                                                          );
                                                          // só calcula se tiver no mínimo 8 valores
                                                          if (listaSazonalidade.length < 8) {
                                                              return (
                                                                  <>
                                                                      <div>{`${ciclo}:`}</div>
                                                                      <div style={{ paddingInline: 14 }}>-</div>
                                                                  </>
                                                              );
                                                          }

                                                          //ordena para as 4 com menor valor ficar no inicio e as 4 maiores no final
                                                          listaSazonalidade.sort((a, b) => a.valor - b.valor);
                                                          let menores = 0;
                                                          for (let i = 0; i < 4; i++) {
                                                              if (listaSazonalidade[i]) {
                                                                  menores += listaSazonalidade[i].valor;
                                                              }
                                                          }
                                                          let maiores = 0;
                                                          for (let i = listaSazonalidade.length; i > listaSazonalidade.length - 4; i--) {
                                                              if (listaSazonalidade[i]) {
                                                                  maiores += listaSazonalidade[i].valor;
                                                              }
                                                          }

                                                          //se estiver iterando

                                                          return (
                                                              <>
                                                                  <div>{`${ciclo}:`}</div>
                                                                  <div>
                                                                      {" "}
                                                                      {` ${parseFloat(menores / maiores)
                                                                          .toFixed(2)
                                                                          .replace(".", ",")}
                                                            `}
                                                                  </div>
                                                              </>
                                                          );
                                                      }
                                                  })()
                                                : "-"}
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader style={{}}>
                    <Row>
                        <Select
                            options={listaUnidadeConsumidora.filter((item) => !filtro.id_cliente || item.id_cliente == filtro.id_cliente)}
                            label="Unidades Consumidoras"
                            cols="12 12 12 12"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_custo_anual}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    unidades_custo_anual: value,
                                });
                            }}
                        />
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_unidade
                                            ? `${String(filtro.competencia_inicial_unidade).substring(0, 4)}/${String(filtro.competencia_inicial_unidade).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_unidade: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_unidade
                                            ? `${String(filtro.competencia_final_unidade).substring(0, 4)}/${String(filtro.competencia_final_unidade).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_unidade: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={async () => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_unidade: null,
                                        competencia_final_unidade: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoUnidadeAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                        color: "#fff",
                    }}
                >
                    <div id="graficoCustoUnidadeMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}>UC</Th>
                                    <Th alignCenter>Janeiro</Th>
                                    <Th alignCenter>Fevereiro</Th>
                                    <Th alignCenter>Março</Th>
                                    <Th alignCenter>Abril</Th>
                                    <Th alignCenter>Maio</Th>
                                    <Th alignCenter>Junho</Th>
                                    <Th alignCenter>Julho</Th>
                                    <Th alignCenter>Agosto</Th>
                                    <Th alignCenter>Setembro</Th>
                                    <Th alignCenter>Outubro</Th>
                                    <Th alignCenter>Novembro</Th>
                                    <Th alignCenter>Dezembro</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {((listaGraficoCustoUnidadeMensal[0] && listaGraficoCustoUnidadeMensal[0].tipos) || [])
                                    .sort((a, b) => a.tipo - b.tipo)
                                    .filter(
                                        (tipo) =>
                                            (filtro.unidades_custo_anual || []).length == 0 ||
                                            (filtro.unidades_custo_anual || []).filter((itemFiltro) => itemFiltro.label == tipo.tipo).length > 0
                                    )
                                    .map((tipo) => (
                                        <Tr key={tipo.tipo}>
                                            <Td color="#fff">{tipo.tipo}</Td>
                                            {listaGraficoCustoUnidadeMensal.map((mes) => {
                                                if (mes.tipos.filter((tipoMes) => tipoMes.tipo == tipo.tipo).length > 0) {
                                                    return mes.tipos
                                                        .filter((tipoMes) => tipoMes.tipo == tipo.tipo)
                                                        .map((tipoMes) => {
                                                            return (
                                                                <Td key={tipoMes.tipo} alignCenter color="#fff">
                                                                    {parseFloat(tipoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={tipo.tipo} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo,listaGraficoCustoUnidadeMensal)}
                                        </Tr>
                                    ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período A</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_antes).substring(0, 4)}/${String(
                                                          filtro.competencia_inicial_custo_comparativo_antes
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_antes: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_final_custo_comparativo_antes).substring(0, 4)}/${String(
                                                          filtro.competencia_final_custo_comparativo_antes
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_antes: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período B</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_depois).substring(0, 4)}/${String(
                                                          filtro.competencia_inicial_custo_comparativo_depois
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_depois: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_final_custo_comparativo_depois).substring(0, 4)}/${String(
                                                          filtro.competencia_final_custo_comparativo_depois
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_depois: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>

                        <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Atualizar"
                                type={"primary"}
                                icon={"fa fa-sync"}
                                event={async () => {
                                    listar();
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <p className="text-center" style={{ fontSize: 16 }}>
                        Tabela Comparativa para Diferentes Períodos
                    </p>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Unidade</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_antes && filtro.competencia_final_custo_comparativo_antes
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_antes).substring(4, 6)}/${String(
                                                  filtro.competencia_inicial_custo_comparativo_antes
                                              ).substring(0, 4)} até ${String(filtro.competencia_final_custo_comparativo_antes).substring(4, 6)}/${String(
                                                  filtro.competencia_final_custo_comparativo_antes
                                              ).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                    <Th alignCenter>Comparativo</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_depois && filtro.competencia_final_custo_comparativo_depois
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_depois).substring(4, 6)}/${String(
                                                  filtro.competencia_inicial_custo_comparativo_depois
                                              ).substring(0, 4)} até ${String(filtro.competencia_final_custo_comparativo_depois).substring(4, 6)}/${String(
                                                  filtro.competencia_final_custo_comparativo_depois
                                              ).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaCustoPorUnidadePeriodo.map((item, i) => {
                                    function calcularComparativo(item) {
                                        let comparativo =
                                            parseFloat(item.valor_atual) > 0 && parseFloat(item.valor_anterior) > 0
                                                ? (parseFloat(item.valor_anterior) * 100) / parseFloat(item.valor_atual)
                                                : 0;
                                        if (comparativo > 0) {
                                            comparativo = 100 - comparativo;
                                        }
                                        return comparativo;
                                    }

                                    const objetoTotais = listaCustoPorUnidadePeriodo.reduce(
                                        (objetoTotais, elemento) => {
                                            objetoTotais.valor_anterior += parseFloat(elemento.valor_anterior || 0);
                                            objetoTotais.valor_atual += parseFloat(elemento.valor_atual || 0);
                                            return objetoTotais;
                                        },
                                        { valor_anterior: 0, valor_atual: 0 }
                                    );

                                    const comparativo = calcularComparativo(item);
                                    const comparativoTotais = calcularComparativo(objetoTotais);

                                    const linhaTotais = (
                                        <Tr key={"Todas Unidades"}>
                                            <Td color="#fff" backgroundColor="#257bfb">
                                                <b>Todas Unidades</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>
                                                    R${" "}
                                                    {parseFloat(objetoTotais.valor_anterior || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor={comparativoTotais == 0 ? "#257bfb" : comparativoTotais > 0 ? "#ef5350" : "#28a745"}>
                                                <b>{parseFloat(objetoTotais.valor_atual) == 0 ? `100,00` : comparativoTotais.toFixed(2).replace(".", ",")}%</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>
                                                    R${" "}
                                                    {parseFloat(objetoTotais.valor_atual || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </b>
                                            </Td>
                                        </Tr>
                                    );

                                    return (
                                        <>
                                            {i == 0 ? linhaTotais : null}
                                            <Tr key={item.nome_unidade_consumidora}>
                                                <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                <Td alignCenter color="#fff">
                                                    R${" "}
                                                    {parseFloat(item.valor_anterior || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                                <Td alignCenter color="#fff" backgroundColor={comparativo == 0 ? null : comparativo > 0 ? "#ef5350" : "#28a745"}>
                                                    {parseFloat(item.valor_atual) == 0 ? `100,00` : comparativo.toFixed(2).replace(".", ",")}%
                                                </Td>
                                                <Td alignCenter color="#fff">
                                                    R${" "}
                                                    {parseFloat(item.valor_atual || 0)
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                </Td>
                                            </Tr>
                                        </>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            {/*<ContentCard style={{ color: '#fff', padding: 8 }}>
					<ContentCardBody style={{ backgroundColor: '#30303d' }}>
						<div id='graficoTarifaMediaAnual' style={{ width: '100%', height: '400px' }}></div>
					</ContentCardBody>
				</ContentCard>*/}
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Média</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifa.map((ano, i) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={i}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMedia.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo,listaGraficoTarifaMedia)}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoTarifaMediaMensalGeral" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                    <Th alignCenter>Média</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {anosTarifaGeral.map((ano) => {
                                    let tarifaMediaAnual = 0;
                                    let quantidadeTarifas = 0;

                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>
                                            {listaGraficoTarifaMediaGeral.map((mes) => {
                                                if (mes.anos.filter((anoMes) => anoMes.ano == ano).length > 0) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            if (parseFloat(anoMes.valor || 0) > 0) {
                                                                tarifaMediaAnual += parseFloat(anoMes.valor || 0);
                                                                quantidadeTarifas++;
                                                            }

                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(6)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo,listaGraficoTarifaMediaGeral)}

                                            <Td alignCenter color="#fff">
                                                {(tarifaMediaAnual > 0 && quantidadeTarifas > 0 ? tarifaMediaAnual / quantidadeTarifas : 0).toFixed(6).replace(".", ",")}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>

            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Select
                        name="ano"
                        options={Array.from({ length: 20 }, (v, i) => ({
                            id: new Date().getFullYear() - 5 + i,
                            valor: new Date().getFullYear() - 5 + i,
                        }))}
                        label="Ano"
                        cols="12 6 2"
                        placeholder="Selecione o ano"
                        value={filtro.anoAcumulado}
                        onChange={(value) => {
                            setFiltro({
                                ...filtro,
                                anoAcumulado: value,
                            });
                            listar();
                        }}
                    />
                </ContentCardHeader>

                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="graficoCustoPorUnidadeAcumuladoAno"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoCustoPorUnidadeAcumuladoAno || []).length * 50 < 400 ? 400 : listaGraficoCustoPorUnidadeAcumuladoAno.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	contratoDemanda: state.analise.contratoDemanda,
	listaGraficoCustoAnual: state.analise.listaGraficoCustoAnual,
	listaGraficoTarifaMedia: state.analise.listaGraficoTarifaMedia,
	listaGraficoTarifaMediaGeral: state.analise.listaGraficoTarifaMediaGeral,
	listaGraficoConsumoAnual: state.analise.listaGraficoConsumoAnual,
	listaCustoPorUnidadePeriodo: state.analise.listaCustoPorUnidadePeriodo,
	listaGraficoCustoUnidadeAnual: state.analise.listaGraficoCustoUnidadeAnual,
	listaGraficoCustoUnidadeMensal: state.analise.listaGraficoCustoUnidadeMensal,
	listaGraficoCustoPorUnidadeAcumuladoAno: state.analise.listaGraficoCustoPorUnidadeAcumuladoAno,
	listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getDemanda,
			getListaGraficoCustoAnual,
			getListaGraficoTarifaMedia,
			getListaGraficoTarifaMediaGeral,
			getListaGraficoConsumoAnual,
			getListaCustoPorUnidadePeriodo,
			getListaGraficoCustoPorUnidadeMensal,
			getListaGraficoCustoPorUnidadeAcumuladoAno,
			getListaGraficoCustoPorUnidadeAnual,
			setFiltro,
		},
		dispatch
	);
const AnaliseCustoConsumo = connect(mapStateToProps, mapDispatchToProps)(analiseCustoConsumo);
export { AnaliseCustoConsumo };
