import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import FormatUtils from "../common/formatUtils/FormatUtils";
import confirmDialog from "../common/dialog/confirmDialog";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
export function setModoTela(modo, registro = {}) {
    return {
        type: "DEMANDA_CONTRATO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "DEMANDA_CONTRATO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("demandaContratoForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "DEMANDA_CONTRATO_FILTRO",
        payload: filtro,
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            const filtro = getState().demandaContrato.filtro;

            //busca lista de UC
            dispatch(setAguardando(true));
            await dispatch(getUcValidarUsuarioImplantacao());

            const resp = await axios.get(
                `${consts.API_URL}/demandaContrato?id_cliente=${filtro.id_cliente || null}&id_tipo_contrato_demanda=${
                    filtro.id_tipo_contrato_demanda || 0
                }&id_unidade_consumidora=${filtro.id_unidade_consumidora || null}`
            );

            const listaFiltrada = adicionarBloqueioUsuarioImplantacao(getState().auth.usuarioLogado, getState().cliente.listaUcValidarUsuarioImplantacao, resp.data);
            dispatch({
                type: "DEMANDA_CONTRATO_LISTADO",
                payload: { data: listaFiltrada },
            });
        } catch (ex) {
            setError("Não foi possível carregar a lista de Contratos de Demanda");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function atualizarValoresFormulario(formularioValues) {
    return async (dispatch, getState) => {
        try {
            if (!formularioValues.id) return {};
            const filtro = getState().demandaContrato.filtro;

            //busca lista de UC
            dispatch(setAguardando(true));
            await dispatch(getUcValidarUsuarioImplantacao());

            //retorna apenas 1 contrato
            const resp = await axios.get(`${consts.API_URL}/demandaContrato?
			id=${formularioValues.id}
            &id_cliente=${formularioValues.id_cliente}&id_tipo_contrato_demanda=${filtro.id_tipo_contrato_demanda || 0}&id_unidade_consumidora=${
                formularioValues.id_unidade_consumidora
            }`);

            const contratoDemanda = resp.data[0];
            const valores = {};
            for (let i = 1; i <= 12; i++) {
                valores[`valor_${i}`] = parseFloat(contratoDemanda[`valor_${i}`]) > 0 ? parseFloat(contratoDemanda[`valor_${i}`]) : null;
                valores[`valor_${i}_ponta`] = parseFloat(contratoDemanda[`valor_${i}_ponta`]) > 0 ? parseFloat(contratoDemanda[`valor_${i}_ponta`]) : null;
                valores[`valor_${i}_geracao`] = parseFloat(contratoDemanda[`valor_${i}_geracao`]) > 0 ? parseFloat(contratoDemanda[`valor_${i}_geracao`]) : null;
            }
            const formularioValuesAtualizado = {
                ...contratoDemanda,
                competencia_base_sazonalidade: contratoDemanda.competencia_base_sazonalidade
                    ? `${String(contratoDemanda.competencia_base_sazonalidade).substring(4, 6)}/${String(contratoDemanda.competencia_base_sazonalidade).substring(0, 4)}`
                    : null,
                ...valores,
            };
            dispatch(initForm(formularioValuesAtualizado));
        } catch (ex) {
            setError("Não foi buscar o Contrato de Demanda");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

function validarCompetencia(competenciaTexto) {
    //tem q vir no formato 'xx/xxxx'
    if (!String(competenciaTexto).includes("/")) return;
    const mes = parseInt(competenciaTexto.split("/")[0]);
    const ano = parseInt(competenciaTexto.split("/")[1]);
    if (isNaN(mes) || isNaN(ano)) return;
    if (mes < 1 || mes > 12) return;
    return true;
}

function converterCompetenciaParaInteiro(competenciaTexto) {
    const mes = competenciaTexto.split("/")[0];
    const ano = competenciaTexto.split("/")[1];
    return parseInt(ano + mes);
}

function listarPeriodoTeste(registro) {
    if ((registro.competencia_fim_periodo_teste || registro.competencia_inicio_periodo_teste) && !validarCompetencia(registro.competencia_inicio_periodo_teste)) {
        throw new Error("Competência inicial do período de teste é inválida");
    }
    if ((registro.competencia_fim_periodo_teste || registro.competencia_inicio_periodo_teste) && !validarCompetencia(registro.competencia_fim_periodo_teste)) {
        throw new Error("Competência final do período de teste é inválida");
    }
    if (validarCompetencia(registro.competencia_inicio_periodo_teste) && validarCompetencia(registro.competencia_fim_periodo_teste)) {
        const competenciaInicioTeste = converterCompetenciaParaInteiro(registro.competencia_inicio_periodo_teste);
        const competenciaFimTeste = converterCompetenciaParaInteiro(registro.competencia_fim_periodo_teste);
        if (competenciaFimTeste < competenciaInicioTeste) {
            throw new Error("Competência final do período de teste não pode ser menor que a inicial");
        }

        const { listarMesesAnosEntreCompetencias } = require("../utils/listarMesesAnosEntreCompetencias");
        return listarMesesAnosEntreCompetencias(registro.competencia_inicio_periodo_teste, registro.competencia_fim_periodo_teste);
    }
}

function validarValoresContratoDemanda({ valores, id_tipo_contrato_demanda }) {
    const regex = /^valor_(?!.*(?:_ponta|_geracao))/;

    const valoresForaPonta = Object.entries(valores)
        .filter(([key, value]) => regex.test(key))
        .map(([key, value]) => value);

    //só vale se todos tiver valor ou todos forem nulos
    if (id_tipo_contrato_demanda == 1 && valoresForaPonta.some((valor) => isNaN(parseFloat(valor)))) {
        throw new Error("Existem campos vazios no Contrato de Demanda Fora Ponta");
    }
    const valoresPonta = Object.entries(valores)
        .filter(([key, value]) => key.includes("_ponta"))
        .map(([key, value]) => value);

    if (id_tipo_contrato_demanda == 2 && valoresPonta.some((valor) => isNaN(parseFloat(valor)))) {
        throw new Error("Existem campos vazios no Contrato de Demanda Ponta");
    }
    const valoresGeracao = Object.entries(valores)
        .filter(([key, value]) => key.includes("_geracao"))
        .map(([key, value]) => value);
    if (id_tipo_contrato_demanda == 3 && valoresGeracao.some((valor) => isNaN(parseFloat(valor)))) {
        throw new Error("Existem campos vazios no Contrato de Demanda Geração");
    }
}

export function salvar(registro) {
    return (dispatch, getState) => {
        try {
            let competencia_base_sazonalidade = null;
            //valida a competencia_base_sazonalidade para contrato não sazonal
            if (!registro.sazonal) {
                const mes = parseInt((((registro.competencia_base_sazonalidade || "").split("/")[0] || "").match(/\d+/g) || []).join("").trim());
                const ano = parseInt((((registro.competencia_base_sazonalidade || "").split("/")[1] || "").match(/\d+/g) || []).join("").trim());
                competencia_base_sazonalidade = String(mes < 10 ? "0" + mes : mes).length == 2 && String(ano).length == 4 ? `${ano}${mes < 10 ? "0" + mes : mes}` : null;
            }

            const id_tipo_contrato_demanda = getState().demandaContrato.filtro.id_tipo_contrato_demanda;
            const periodo_teste = listarPeriodoTeste(registro);
            const auth = getState().auth;
            const valores = {};
            for (let i = 1; i <= 12; i++) {
                valores[`valor_${i}`] = parseFloat(String(registro[`valor_${i}`]).replace(",", ".")) || null;
                valores[`valor_${i}_ponta`] = parseFloat(String(registro[`valor_${i}_ponta`]).replace(",", ".")) || null;
                valores[`valor_${i}_geracao`] = parseFloat(String(registro[`valor_${i}_geracao`]).replace(",", ".")) || null;
            }
            //lança erro se tiver campos vazios
            validarValoresContratoDemanda({ valores, id_tipo_contrato_demanda });
            if (!registro.id) {
                const salvarDemanda = async () => {
                    try {
                        dispatch(setAguardando(true));
                        const resp = await axios.post(`${consts.API_URL}/demandaContrato`, {
                            ...registro,
                            ...valores,
                            id_usuario_inclusao: auth.usuario.id,
                            periodo_teste,
                            encerrado: false,
                            competencia_base_sazonalidade,
                        });
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(
                            initForm({
                                ...registro,
                                id: resp.data.id,
                            })
                        );
                        dispatch(getListaDemandaCompetencia(resp.data.id));
                    } catch (e) {
                        setErroAPI(e);
                    } finally {
                        dispatch(setAguardando(false));
                    }
                };
                if (auth.usuarioLogado.acesso_periodo_teste_adicionar && (!periodo_teste || !periodo_teste.length)) {
                    confirmDialog("Deseja realmente salvar o registro sem incluir um período de teste?", salvarDemanda);
                } else {
                    salvarDemanda();
                }
            } else {
                const alterarDemanda = async () => {
                    try {
                        dispatch(setAguardando(true));
                        await axios.put(`${consts.API_URL}/demandaContrato`, {
                            ...registro,
                            ...valores,
                            id_usuario_alteracao: auth.usuario.id,
                            periodo_teste,
                            competencia_base_sazonalidade,
                        });

                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(
                            initForm({
                                ...registro,
                            })
                        );
                        dispatch(getListaDemandaCompetencia(registro.id));
                    } catch (e) {
                        setErroAPI(e);
                    } finally {
                        dispatch(setAguardando(false));
                    }
                };
                confirmDialog("Deseja realmente alterar?", alterarDemanda);
            }
        } catch (error) {
            setError(error.message);
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        const excluirDemanda = async () => {
            try {
                await axios.delete(`${consts.API_URL}/demandaContrato?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`);
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(setModoTela("lista"));
            } catch (e) {
                setErroAPI(e);
            } finally {
                dispatch(setAguardando(false));
            }
        };
        confirmDialog("Deseja realmente excluir?", excluirDemanda);
    };
}
export function encerrar(registro) {
    return (dispatch, getState) => {
        const encerrarDemanda = async () => {
            try {
                await axios.put(`${consts.API_URL}/demandaContrato/encerrar`, { id: registro.id, id_usuario_alteracao: getState().auth.usuario.id });
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(setModoTela("lista"));
            } catch (e) {
                setErroAPI(e);
            } finally {
                dispatch(setAguardando(false));
            }
        };
        confirmDialog("Deseja realmente encerrar o contrato?", encerrarDemanda);
    };
}
export function reabrir(registro) {
    return (dispatch, getState) => {
        const reabrirDemanda = async () => {
            try {
                const listaContratos = getState().demandaContrato.lista;
                const ucJaPossuiContrato = listaContratos.find((contrato) => contrato.id_unidade_consumidora == registro.id_unidade_consumidora && !contrato.encerrado);
                if (ucJaPossuiContrato) {
                    setError(
                        `A UC ${registro.nome_unidade_consumidora} já possui um contrado de demanda ${
                            registro.sazonal ? "Sazonal" : "Não Sazonal"
                        } ativo, não é possível ter dois contratos ativos simultaneamente.`
                    );
                    return {};
                }
                await axios.put(`${consts.API_URL}/demandaContrato/reabrir`, { id: registro.id, id_usuario_alteracao: getState().auth.usuario.id });
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(setModoTela("lista"));
            } catch (e) {
                setErroAPI(e);
            } finally {
                dispatch(setAguardando(false));
            }
        };
        confirmDialog("Deseja realmente reabrir o contrato?", reabrirDemanda);
    };
}

//Importação
export function importarContrato(registro, base64) {
    return async (dispatch, getState) => {
        try {
            const registroComBase64 = {
                ...registro,
                arquivo: base64,
            };
            dispatch(setAguardando(true));
            await axios.post(`${consts.API_URL}/demandaContrato/importarContrato`, {
                id: registro.id,
                base64: base64,
                id_usuario_alteracao: getState().auth.usuario.id,
            });
            setSuccess("Operação Realizada com sucesso.");
            dispatch(setModoTela("cadastro", { ...registroComBase64 }));
            dispatch(initForm({ ...registroComBase64 }));
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaContrato(id, nomeArquivo) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarContrato?id=${id}`);
            if (resp.data && resp.data.arquivo) {
                const linkSource = resp.data.arquivo;
                const downloadLink = document.createElement("a");
                const fileName = `${nomeArquivo}.${resp.data.arquivo.split("/")[1].split(";")[0]}`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                dispatch(downloadLink.click());
            }
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function importarCusd(registro, base64) {
    return async (dispatch, getState) => {
        const registroComArquivo = {
            ...registro,
            arquivo_cusd: base64,
        };
        try {
            dispatch(setAguardando(true));
            await axios.post(`${consts.API_URL}/demandaContrato/importarCusd`, {
                id: registro.id,
                base64: base64,
                id_usuario_alteracao: getState().auth.usuario.id,
            });
            setSuccess("Operação Realizada com sucesso.");
            dispatch(setModoTela("cadastro", { ...registroComArquivo }));
            dispatch(
                initForm({
                    ...registroComArquivo,
                })
            );
            dispatch(getLista());
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaCusd(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCusd?id=${id}`);
            if (resp.data && resp.data.arquivo_cusd) {
                dispatch(setArquivoPDF(resp.data.arquivo_cusd, nomePdf));
            }
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function importarCcer(registro, base64) {
    return async (dispatch, getState) => {
        const registroComArquivo = {
            ...registro,
            arquivo_ccer: base64,
        };
        try {
            dispatch(setAguardando(true));
            await axios.post(`${consts.API_URL}/demandaContrato/importarCcer`, {
                id: registro.id,
                base64: base64,
                id_usuario_alteracao: getState().auth.usuario.id,
            });
            setSuccess("Operação Realizada com sucesso.");
            dispatch(setModoTela("cadastro", { ...registroComArquivo }));
            dispatch(
                initForm({
                    ...registroComArquivo,
                })
            );
            dispatch(getLista());
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaCcer(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCcer?id=${id}`);
            if (resp.data && resp.data.arquivo_ccer) {
                dispatch(setArquivoPDF(resp.data.arquivo_ccer, nomePdf));
            }
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function importarCicloRural(registro, base64) {
    return async (dispatch, getState) => {
        const registroComArquivo = {
            ...registro,
            arquivo_ciclo_rural: base64,
        };
        try {
            dispatch(setAguardando(true));
            await axios.post(`${consts.API_URL}/demandaContrato/importarCicloRural`, {
                id: registro.id,
                base64: base64,
                id_usuario_alteracao: getState().auth.usuario.id,
            });
            setSuccess("Operação Realizada com sucesso.");
            dispatch(setModoTela("cadastro", { ...registroComArquivo }));
            dispatch(
                initForm({
                    ...registroComArquivo,
                })
            );
            dispatch(getLista());
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function buscaCicloRural(id, nomePdf) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/importarCicloRural?id=${id}`);
            if (resp.data && resp.data.arquivo_ciclo_rural) {
                dispatch(setArquivoPDF(resp.data.arquivo_ciclo_rural, nomePdf));
            }
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "DEMANDA_CONTRATO_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "DEMANDA_CONTRATO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "DEMANDA_CONTRATO_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function setListaDemandaCompetenciaAlteracao(modo, lista = []) {
    return {
        type: "DEMANDA_COMPETENCIA_LISTA_ALTERACAO",
        payload: {
            modo: modo,
            lista: lista,
        },
    };
}

export function getListaDemandaCompetencia(id_demanda_contrato) {
    return async (dispatch, getState) => {
        const filtro = getState().demandaContrato.filtro;
        const request = await axios.get(
            `${consts.API_URL}/demandaCompetencia?id_demanda_contrato=${id_demanda_contrato || 0}&id_tipo_contrato_demanda=${filtro.id_tipo_contrato_demanda || 0}`
        );
        dispatch({
            type: "DEMANDA_COMPETENCIA_LISTADO",
            payload: request,
        });
    };
}

export function adicionarHistorico(id_demanda_contrato, lista) {
    return async (dispatch, getState) => {
        const adicionarHistoricoDemanda = async () => {
            dispatch(setAguardando(true));
            let erro = false;

            const manipularErro = (e) => {
                setErroAPI(e);
                erro = true;
            };
            //a trigger do banco que adiciona o id_tipo_contrato_demanda conforme os valores enviados
            for (const item of lista) {
                await axios
                    .post(`${consts.API_URL}/demandaCompetencia`, {
                        ...item,
                        id_usuario_inclusao: getState().auth.usuario.id,
                    })
                    .catch(manipularErro);
            }

            if (!erro) {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(getListaDemandaCompetencia(id_demanda_contrato));
            }
            dispatch(setAguardando(false));
        };
        confirmDialog("Deseja realmente adicionar histórico?", adicionarHistoricoDemanda);
    };
}

export function alterarHistorico(id_demanda_contrato, lista) {
    return async (dispatch, getState) => {
        const alterarHistoricoDemanda = async () => {
            dispatch(setAguardando(true));
            let erro = false;
            const manipularErro = (e) => {
                setErroAPI(e);
                erro = true;
            };
            for (const item of lista) {
                await axios
                    .put(`${consts.API_URL}/demandaCompetencia`, {
                        ...item,
                        quantidade: FormatUtils.formatarValorBanco(item.quantidade, 2),
                        id_usuario_alteracao: getState().auth.usuario.id,
                    })
                    .catch(manipularErro);
            }

            if (!erro) {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(getListaDemandaCompetencia(id_demanda_contrato));
                dispatch(setListaDemandaCompetenciaAlteracao("lista", []));
            }
            dispatch(setAguardando(false));
        };
        confirmDialog("Deseja realmente alterar?", alterarHistoricoDemanda);
    };
}

export function getListaHistorico(id_demanda_contrato, competencia_inicio, competencia_fim) {
    return async (dispatch, getState) => {
        const filtro = getState().demandaContrato.filtro;
        const request = await axios.get(`${consts.API_URL}/demandaCompetencia/historico
			?id_demanda_contrato=${id_demanda_contrato || 0}
			&id_tipo_contrato_demanda=${filtro.id_tipo_contrato_demanda || 0}
			&competencia_inicio=${competencia_inicio || 0}
			&competencia_fim=${competencia_fim || 0}`);
        dispatch({
            type: "DEMANDA_COMPETENCIA_HISTORICO_LISTADO",
            payload: request,
        });
    };
}

//AGENDAMENTO

export function initFormAgendamento(registro = {}) {
    return [initialize("demandaAgendamentoForm", registro)];
}

export function getListaAgendamento(idDemanda) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: "DEMANDA_CONTRATO_AGENDAMENTO_LISTADO",
                payload: { data: [] },
            });

            const resp = await axios.get(
                `${consts.API_URL}/demandaAgendamento?id_demanda_contrato=${idDemanda || 0}&id_tipo_contrato_demanda=${getState().demandaContrato.filtro.id_tipo_contrato_demanda}`
            );
            dispatch({
                type: "DEMANDA_CONTRATO_AGENDAMENTO_LISTADO",
                payload: resp,
            });
        } catch (erro) {
            setErroAPI(erro);
        }
    };
}
function validarValoresAgendamento(objetoValores) {
    const valores = Object.entries(objetoValores)
        .filter(([key, value]) => key.includes("valor_"))
        .map(([key, value]) => value);

    if (valores.some((valor) => isNaN(parseFloat(valor)))) {
        throw new Error("Existem campos vazios no agendamento");
    }
}

export function salvarAgendamento(registro) {
    return async (dispatch, getState) => {
        try {
            const mesAtual = new Date().getMonth() + 1;
            const anoAtual = new Date().getFullYear();

            const mes = parseInt((((registro.competencia_inicio_vigencia || "").split("/")[0] || "").match(/\d+/g) || []).join("").trim());

            const ano = parseInt((((registro.competencia_inicio_vigencia || "").split("/")[1] || "").match(/\d+/g) || []).join("").trim());

            if (ano < anoAtual || (ano == anoAtual && mes < mesAtual)) {
                setError("O ínicio de vigência do agendamento deve ser igual ou superior ao mês atual");
                return {};
            }

            const competenciaValidada = String(mes < 10 ? "0" + mes : mes).length == 2 && String(ano).length == 4 ? `${ano}${mes < 10 ? "0" + mes : mes}` : null;

            const valores = {};
            for (let i = 1; i <= 12; i++) {
                valores[`valor_${i}`] = parseFloat(String(registro[`valor_${i}`]).replace(",", ".")) || null;
            }

            //lança erro se tiver campos vazios
            validarValoresAgendamento(valores);
            if (!registro.id) {
                dispatch(setAguardando(true));
                axios
                    .post(`${consts.API_URL}/demandaAgendamento`, {
                        ...registro,
                        competencia_inicio_vigencia: competenciaValidada,
                        ...valores,
                        id_usuario_inclusao: getState().auth.usuario.id,
                        id_usuario_alteracao: getState().auth.usuario.id,
                        id_tipo_contrato_demanda: getState().demandaContrato.filtro.id_tipo_contrato_demanda,
                    })
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getListaAgendamento(registro.id_demanda_contrato));
                        dispatch(setModoTela("cadastro", getState().demandaContrato.registro));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                    })
                    .finally(() => {
                        dispatch(setAguardando(false));
                    });
            } else {
                const alterarAgendamento = async () => {
                    try {
                        dispatch(setAguardando(true));

                        await axios.put(`${consts.API_URL}/demandaAgendamento`, {
                            ...registro,
                            competencia_inicio_vigencia: competenciaValidada,
                            ...valores,
                            id_usuario_alteracao: getState().auth.usuario.id,
                            id_tipo_contrato_demanda: getState().demandaContrato.filtro.id_tipo_contrato_demanda,
                        });
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getListaAgendamento(registro.id_demanda_contrato));
                        dispatch(setModoTela("cadastro", getState().demandaContrato.registro));
                    } catch (e) {
                        setErroAPI(e);
                    } finally {
                        dispatch(setAguardando(false));
                    }
                };
                confirmDialog("Deseja realmente alterar agendamento?", alterarAgendamento);
            }
        } catch (error) {
            setError(error.message);
        }
    };
}

export function excluirAgendamento(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(
                    `${consts.API_URL}/demandaAgendamento?id=${registro.id}&id_usuario_alteracao=${
                        getState().auth.usuario.id
                    }`
                )
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getListaAgendamento(registro.id_demanda_contrato));
                    dispatch(setModoTela("cadastro", getState().demandaContrato.registro));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}
